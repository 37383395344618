import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideToastr } from 'ngx-toastr';

import { routes } from './app.routes';
import { withFetch, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { httpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { authInterceptor, provideAuth } from 'angular-auth-oidc-client';
import { env } from '../environments/environment';
import { CustomDateParserFormatter } from './shared/components/datepicker-range/datepicker-range.component';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withFetch(), withInterceptors([authInterceptor(), httpErrorInterceptor()])),
    provideAuth({
      config: {
        clientId: env.OIDC_CLIENT_ID,
        scope: 'openid profile email offline_access',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        ignoreNonceAfterRefresh: true,
        triggerRefreshWhenIdTokenExpired: false,
        unauthorizedRoute: '/unauthorized',
        historyCleanupOff: true,
        authority: env.OIDC_ISSUER,
        redirectUrl: `${window.location.origin}/callback`,
        postLogoutRedirectUri: window.location.origin,
        secureRoutes: [env.API_URL],
      },
    }),
    provideAnimations(),
    provideToastr({
      timeOut: 10000,
      extendedTimeOut: 10000,
      preventDuplicates: true,
      closeButton: true,
      tapToDismiss: false,
    }),
    { provide: LOCALE_ID, useValue: 'de-CH' },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
};

import { Component, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { UserDropdownComponent } from './user-dropdown/user-dropdown.component';
import { Location } from '@angular/common';
import { LocationDropdownComponent } from './location-dropdown/location-dropdown.component';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    UserDropdownComponent,
    LocationDropdownComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  isSellerView: boolean = false;

  constructor(
    private location: Location,
    private roleService: RoleService,
  ) {
    this.roleService.isSeller$.subscribe(isSeller => {
      this.isSellerView =
        isSeller &&
        (this.location.path() === '/cockpit' ||
          this.location.path() === '/price-and-stock' ||
          this.location.path() === '/order-overview/seller' ||
          this.location.path() === '/buyers/');
    });
    // TODO maybe there is a more elegant way to get the seller routes
  }

  setSellerView($event: boolean) {
    this.isSellerView = $event;
  }
}

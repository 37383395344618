import { inject } from '@angular/core';
import { HttpRequest, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import _default from 'chart.js/dist/plugins/plugin.tooltip';

export function httpErrorInterceptor(): HttpInterceptorFn {
  return (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const toast = inject(ToastrService);

    return next(request).pipe(
      tap({
        next: () => {},
        error: error => {
          if (error.status === 401) {
            toast.error('401: Unzureichende Befugnisse');
          }
          if (error.status === 404 && error.url.includes('fertilizer_price_market/latest')) {
            return;
          }
          if (error.status === 404) {
            toast.error('404: Seite nicht gefunden');
          }
          if (error.status !== 401 && error.status !== 404) {
            toast.error('Fehler: ' + error.message);
          }
        },
      }),
    );
  };
}

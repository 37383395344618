import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { env } from '../../../environments/environment';
import {
  IOrderCreate,
  IOrderOverview,
  IOrderUpdateStatus,
} from '../../shared/types/api_models/order';
import { IProduct } from '../../shared/types/api_models/product';
import { HttpRequestOptions } from '../../shared/types/request';
import { IUserRead } from '../../shared/types/api_models/user';
import { IPlant } from '../../shared/types/api_models/plant';
import {
  IPricingParametersCreate,
  IPricingParametersRead,
} from '../../shared/types/api_models/pricing_parameters';
import { IBasePrice, ISalesPrice } from '../../shared/types/api_models/price';
import { formatDate } from '@angular/common';
import {
  IStockParametersCreate,
  IStockParametersRead,
} from '../../shared/types/api_models/stock_parameters';
import { IStockUpdateCreate, IStockRead } from '../../shared/types/api_models/stock';
import { IPickupCreate, IPickupRead } from '../../shared/types/api_models/pickup';
import { IAvailability } from '../../shared/types/api_models/availability';
import {
  IFertilizerMarketPriceParametersCreate,
  IFertilizerMarketPriceParametersRead,
} from '../../shared/types/api_models/fertilizer_market_price';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public apiUrl: string = '';
  constructor(public httpClient: HttpClient) {
    this.apiUrl = env.API_URL;
  }

  public getProducts(): Observable<IProduct[]> {
    return this.request('GET', 'products');
  }

  public getOrders(): Observable<IOrderOverview[]> {
    return this.request('GET', 'orders/overview');
  }

  public getOrdersPeriod(
    productId: number,
    startDate: Date,
    endDate: Date,
  ): Observable<IOrderOverview[]> {
    return this.request(
      'GET',
      `orders/${productId}/${this.formatDateHelper(startDate)}/${this.formatDateHelper(endDate)}`,
    );
  }

  public getOrder(orderId: number): Observable<IOrderOverview> {
    return this.request('GET', `orders/${orderId}`);
  }

  public createOrder(order: IOrderCreate): Observable<IOrderOverview> {
    return this.request('POST', 'orders', {
      body: order,
    });
  }

  public updateOrderStatus(order: IOrderUpdateStatus): Observable<IOrderOverview> {
    return this.request('PUT', `orders/${order.id}`, {
      body: order,
    });
  }

  cancelOrder(orderId: number) {
    return this.request('PUT', `orders/cancel/${orderId}`);
  }

  public getPickups(orderId: number): Observable<IPickupRead[]> {
    return this.request('GET', `pickups/orders/${orderId}`);
  }

  public addPickup(pickup: IPickupCreate): Observable<IPickupRead> {
    return this.request('POST', 'pickups', {
      body: pickup,
    });
  }

  public getBuyerOrders(): Observable<IOrderOverview[]> {
    return this.request('GET', `orders/buyer`);
  }

  public getUsers(): Observable<IUserRead[]> {
    return this.request('GET', 'users');
  }

  public getPlants(): Observable<IPlant[]> {
    return this.request('GET', 'plants');
  }

  public getPlant(plantId: number): Observable<IPlant> {
    return this.request('GET', `plants/${plantId}`);
  }

  public getPricingParameters(productId: number): Observable<IPricingParametersRead> {
    return this.request('GET', `pricing_parameters/latest/${productId}`);
  }

  public setPricingParameters(
    pricingParams: IPricingParametersCreate,
  ): Observable<IPricingParametersRead> {
    return this.request('POST', `pricing_parameters`, {
      body: pricingParams,
    });
  }

  public getFertilizerMarketPrice(
    productId: number,
  ): Observable<IFertilizerMarketPriceParametersRead> {
    try {
      return this.request('GET', `fertilizer_price_market/latest/${productId}`);
    } catch (e) {
      return new Observable(undefined);
    }
  }

  public setFertilizerMarketPrice(
    params: IFertilizerMarketPriceParametersCreate,
  ): Observable<IPricingParametersRead> {
    return this.request('POST', `fertilizer_price_market`, {
      body: params,
    });
  }

  public getStockParameters(productId: number): Observable<IStockParametersRead> {
    return this.request('GET', `stock_parameters/latest/${productId}`);
  }

  public setStockParameters(stockParams: IStockParametersCreate): Observable<IStockParametersRead> {
    return this.request('POST', `stock_parameters`, {
      body: stockParams,
    });
  }

  public getStock(productId: number, date: Date): Observable<IStockRead> {
    return this.request('GET', `stocks/${this.formatDateHelper(date)}`, {
      params: { product_id: productId },
    });
  }

  public getStocksPeriod(
    productId: number,
    startDate: Date,
    endDate: Date,
  ): Observable<IStockRead[]> {
    return this.request(
      'GET',
      `stocks/products/${productId}/${this.formatDateHelper(startDate)}/${this.formatDateHelper(
        endDate,
      )}`,
    );
  }

  public setStock(stock: IStockUpdateCreate, date: Date): Observable<IStockRead> {
    return this.request('PUT', `stocks/${this.formatDateHelper(date)}`, {
      params: {
        ...stock,
      },
    });
  }

  public recalculateStock(productId: number): Observable<IStockRead> {
    return this.request('POST', `stocks/recalculate/${productId}`);
  }

  public getBasePrice(productId: number, date: Date): Observable<IBasePrice> {
    return this.request('GET', `prices/base_prices/${this.formatDateHelper(date)}`, {
      params: { product_id: productId },
    });
  }

  public setBasePricePeriod(
    productId: number,
    basePrice: number,
    startDate: Date,
    endDate: Date,
  ): Observable<IBasePrice> {
    return this.request(
      'PUT',
      `prices/base_prices/${this.formatDateHelper(startDate)}/${this.formatDateHelper(endDate)}`,
      { params: { product_id: productId, price: basePrice } },
    );
  }

  public computeMinMaxPrice(
    productId: number,
    basePrice: number,
    parameters?: IPricingParametersCreate,
  ): Observable<number[]> {
    let options: HttpRequestOptions;
    if (!parameters) {
      options = {
        params: {
          base_price: basePrice,
        },
      };
    } else {
      options = {
        params: {
          base_price: basePrice,
        },
        body: parameters,
      };
    }
    return this.request('POST', `pricing_parameters/min_max_price/${productId}`, options);
  }

  public checkAvailability(
    productId: number,
    amount: number,
    startDate: string,
    endDate: string,
  ): Observable<IAvailability> {
    return this.request('POST', `stocks/check_availability/${productId}`, {
      params: {
        amount: amount,
        pickup_start_date: startDate,
        pickup_end_date: endDate,
      },
    });
  }

  public getSalesPrice(
    productId: number,
    amount: number,
    startDate: string,
    endDate: string,
  ): Observable<ISalesPrice> {
    return this.request('GET', 'prices/sales_prices', {
      params: {
        product_id: productId,
        amount: amount,
        start_date: startDate,
        end_date: endDate,
      },
    });
  }

  public getMapKey(): Observable<string> {
    return this.request('GET', 'map');
  }

  public assignUserToOrganization(userId: number, organizationId: number): Observable<IUserRead> {
    return this.request('PUT', 'users/assign_to_organization', {
      params: { user_id: userId, organization_id: organizationId },
    });
  }

  public downloadCsv(orderId: number): Observable<Blob> {
    return this.request('GET', `orders/csv/${orderId}`, { responseType: 'blob' });
  }

  private formatDateHelper(date: Date): string {
    return formatDate(date, 'yyyy-MM-dd', 'en');
  }

  /**
   * Constructs a request and returns an observable
   *
   * @param method The HTTP method.
   * @param url The endpoint URL relative to the api base url.
   * @param options The HTTP options to send with the request.
   * @param baseUrl A url to overwrite the default api base url.
   *
   * @return An `Observable` of the `HttpResponse`.
   */
  private request(
    method: string,
    url: string,
    options: HttpRequestOptions = {},
    baseUrl?: string,
  ): Observable<any> {
    url = (baseUrl ? baseUrl : this.apiUrl) + '/' + url;
    return this.httpClient.request(method, url, options);
  }

  /**
   * Constructs a request and returns a promise
   *
   * @param method The HTTP method.
   * @param url The endpoint URL relative to the api base url.
   * @param options The HTTP options to send with the request.
   * @param baseUrl A url to overwrite the default api base url.
   *
   * @return A `Promise` of the `HttpResponse`.
   */
  private requestAsPromise(
    method: string,
    url: string,
    options?: HttpRequestOptions,
    baseUrl?: string,
  ): Promise<any> {
    let obs = this.request(method, url, options, baseUrl);
    return lastValueFrom(obs);
  }
}

import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../core/services/api.service';
import { IStockRead } from '../../shared/types/api_models/stock';
import { Chart, ChartConfiguration } from 'chart.js/auto';
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
} from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, concat, forkJoin } from 'rxjs';
import { IOrderOverview } from '../../shared/types/api_models/order';

@Component({
  selector: 'app-cockpit',
  standalone: true,
  imports: [CommonModule, NgbDropdown, NgbDropdownToggle, NgbDropdownItem, NgbDropdownMenu],
  templateUrl: './cockpit.component.html',
  styleUrl: './cockpit.component.scss',
})
export class CockpitComponent implements OnInit {
  loading = true;
  chart: Chart | undefined;
  orderChart: Chart | undefined;
  productIds: number[] = [];
  productNames: string[] = [];
  productIdSelected$ = new BehaviorSubject<number | null>(null);
  productNameSelected: string | undefined;
  periodsAvailable = [7, 30, 90];
  periodSelected = 90;
  displayLegend = false;

  constructor(private apiService: ApiService) {}

  async ngOnInit() {
    // TODO this could be moved to a service, as it is also used the same way in the pricing parameters
    //  and similar in product order
    this.apiService.getProducts().subscribe(products => {
      this.productIds = products.map(product => product.id);
      this.productNames = products.map(product => product.name);
      this.productIdSelected$.next(this.productIds[0]);
      this.productNameSelected = this.productNames[0];
    });
    this.generateStockChart();
    await this.generateOrderChart();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.setDisplay();
  }

  setDisplay(): void {
    this.displayLegend = window.innerWidth > 768;
  }

  async selectProduct(index: number) {
    this.loading = true;
    this.productIdSelected$.next(this.productIds[index]);
    this.productNameSelected = this.productNames[this.productIds.indexOf(this.productIds[index])];
  }

  async selectPeriod(index: number) {
    this.periodSelected = this.periodsAvailable[index];
    this.generateStockChart();
  }

  private generateStockChart() {
    this.productIdSelected$.subscribe(productId => {
      if (!productId) {
        return;
      }
      const startDate = new Date();
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + this.periodSelected);
      forkJoin([
        this.apiService.getStocksPeriod(productId, startDate, endDate),
        this.apiService.getStockParameters(productId),
        this.apiService.getOrdersPeriod(productId, startDate, endDate),
      ]).subscribe(([stocks, stockParams, orders]) => {
        const labels: string[] = stocks.map((stock: IStockRead) => {
          return stock.date;
        });
        const amounts: number[] = [];
        for (const label of labels) {
          const ordersFiltered = orders.filter(order => order.pickup_start_date === label);
          if (ordersFiltered.length > 0) {
            let amountTotal = 0;
            for (const order of ordersFiltered) {
              amountTotal += order.amount;
            }
            amounts.push(amountTotal);
          } else {
            amounts.push(0);
          }
        }
        const config: ChartConfiguration = {
          type: 'line',
          data: {
            labels: labels,
            datasets: [
              {
                label: 'Lagerbestand Mittel',
                data: stocks.map((stock: IStockRead) => {
                  return stock.level_predicted_avg;
                }),
                pointStyle: false,
                borderColor: '#238c69',
                backgroundColor: '#238c69',
              },
              {
                label: 'Lagerbestand Abholung 1. Tag',
                data: stocks.map((stock: IStockRead) => {
                  return stock.level_predicted_first;
                }),
                pointStyle: false,
                borderColor: '#1B4079',
                backgroundColor: '#1B4079',
              },
              {
                label: 'Lagerbestand Abholung letzter Tag',
                data: stocks.map((stock: IStockRead) => {
                  return stock.level_predicted_last;
                }),
                pointStyle: false,
                borderColor: '#AF4D98',
                backgroundColor: '#AF4D98',
              },
              {
                label: 'Bestellungen',
                data: amounts,
                type: 'bar',
                borderColor: '#fa9c1b',
                backgroundColor: '#FA9C1BB6',
                borderWidth: 2,
                borderRadius: 2,
              },
              {
                label: 'Unterer Schwellenwert',
                data: Array.from({ length: stocks.length }, () => stockParams.threshold_lower),
                pointStyle: false,
                borderColor: '#8b0000f0',
                backgroundColor: '#8b0000f0',
              },
              {
                label: 'Oberer Schwellenwert',
                data: Array.from({ length: stocks.length }, () => stockParams.threshold_upper),
                pointStyle: false,
                borderColor: '#8b0000f0',
                backgroundColor: '#8b0000f0',
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: this.displayLegend,
              },
              title: {
                display: true,
                text: `Prognose Lagerbestand ${this.productNameSelected}`,
                font: {
                  size: 18,
                },
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Datum',
                },
              },
              y: {
                title: {
                  display: true,
                  text: 'Lagerbestand in m³',
                },
              },
            },
          },
        };
        if (this.chart) {
          this.chart.destroy();
        }
        this.chart = new Chart('stockChart', config);
        this.loading = false;
      });
    });
  }

  private async generateOrderChart() {
    this.apiService.getOrders().subscribe(orders => {
      //TODO: There should be a dropdown to select the year as currently the year is not taken into account

      const orderList = new Array(12).fill(0);
      for (const order of orders) {
        const date = new Date(order.pickup_start_date);
        orderList[date.getMonth()] += 1;
      }

      const MONTHS = [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ];

      const data = {
        labels: MONTHS,
        datasets: [
          {
            label: 'Bestellungen pro Monat (Start der Abholung)',
            data: orderList,
            backgroundColor: '#238c69',
          },
        ],
      };
      const config: ChartConfiguration = {
        type: 'bar',
        data: data,
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      };
      if (this.orderChart) {
        this.orderChart.destroy();
      }
      this.orderChart = new Chart('orderChart', config);
      this.loading = false;
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { PRICE_CONSTANT } from '../../modules/pricing/parameters/parameters.component';

@Pipe({
  standalone: true,
  name: 'translateFertilzerMarketPrice',
})
export class TranslateFertilzerMarketPrice implements PipeTransform {
  transform(value: string): string {
    if (value == PRICE_CONSTANT.LOW) return 'Tief';
    if (value == PRICE_CONSTANT.MEDIUM) return 'Mittel';
    return 'Hoch';
  }
}

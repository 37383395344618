import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../services/api.service';
import { IPlant } from '../../../../shared/types/api_models/plant';
import { Router } from '@angular/router';

@Component({
  selector: 'location-dropdown',
  standalone: true,
  templateUrl: './location-dropdown.component.html',
  styleUrl: './location-dropdown.component.scss',
  imports: [CommonModule, NgbDropdownModule],
})
export class LocationDropdownComponent implements OnInit {
  plants: IPlant[] | undefined;
  constructor(
    private apiService: ApiService,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.apiService.getPlants().subscribe(plants => {
      this.plants = plants;
    });
  }

  navigateToPlant(plantId: number) {
    this.router.navigate(['/contact', plantId]);
  }

  isWelcomePage(): boolean {
    return this.router.url === '/welcome';
  }
}

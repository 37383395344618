<div class="container mt-4">
  @if (loadingPricingParameters || loadingStockParameters || loadingBasePrice || loadingStock) {
    <div class="spinner-overlay">
      <div class="spinner-border" role="status"></div>
    </div>
  }
  <div class="row">
    <div class="mb-3 d-flex align-items-center">
      <h2 class="heading">Parameter bearbeiten</h2>
      <div ngbDropdown class="dropdown button-inline mt-0">
        <button ngbDropdownToggle class="btn button-medium button-inline ms-4">
          {{ productNameSelected ? productNameSelected : 'Produkt wählen' }}
        </button>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
          @for (name of productNames; track $index) {
            <div ngbDropdownItem class="dropdown-item" (click)="selectProduct($index)">
              {{ name }}
            </div>
          }
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="border rounded p-3">
        <div class="mb-3 d-md-flex justify-content-md-end">
          <button
            type="button"
            class="btn button-medium-inverted ms-2"
            (click)="factorsCollapsed = !factorsCollapsed"
            [attr.aria-expanded]="!factorsCollapsed"
            aria-controls="collapseExample"
          >
            Faktoren {{ factorsCollapsed ? 'ausklappen' : 'einklappen' }}
          </button>
        </div>
        <form #weightForm="ngForm">
          @for (parameter of parameters; track parameter.name) {
            <div class="form-group row align-items-center">
              <label class="col-md-4 col-form-label mt-2">{{ parameter.name }}</label>
              <div class="col-md-4">
                <input
                  type="range"
                  [(ngModel)]="parameter.weight"
                  name="{{ 'parameter_weight_' + parameter.name }}"
                  (mouseup)="onParameterChanges()"
                  class="form-control-range mr-3"
                  max="20"
                />
              </div>
              <div class="col-md-2">
                <input
                  type="number"
                  [(ngModel)]="parameter.weight"
                  name="{{ 'parameter_weight_' + parameter.name }}"
                  (input)="onParameterChanges()"
                  class="form-control ms-3"
                />
              </div>
              <div class="col-md-auto ms-2">{{ parameter.percentage | formatDecimal: 2 }}%</div>
              <div #collapse="ngbCollapse" [(ngbCollapse)]="factorsCollapsed">
                <div class="card border-0 mt-1 mb-3">
                  <div class="card-body p-lg-0">
                    <table class="table table-bordered">
                      <thead>
                        <tr class="text-small">
                          @for (factorLabel of parameter.factors.labels; track $index) {
                            <th>{{ factorLabel }}</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-small">
                          @for (factor of parameter.factors.values; track $index) {
                            <td>
                              <input
                                type="text"
                                [(ngModel)]="parameter.factors.values[$index]"
                                (input)="onParameterChanges()"
                                name="{{ 'parameter_factor_' + parameter.name + '_' + $index }}"
                                class="form-control form-control-sm text-small p-lg-1"
                              />
                            </td>
                          }
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          }
        </form>
        <div class="mt-3">
          Min Preis:
          @if (!loadingMinMaxPrice) {
            <span> {{ minMaxPrice[0] | formatDecimal: 2 }} &nbsp;&nbsp;&nbsp;</span>
          } @else {
            <span class="spinner-border spinner-border-sm" role="status"></span>
          }
          Max Preis:
          @if (!loadingMinMaxPrice) {
            <span> {{ minMaxPrice[1] | formatDecimal: 2 }} &nbsp; </span>
          } @else {
            <span class="spinner-border spinner-border-sm" role="status"></span>
          }
          für Basispreis:
          @if (!loadingBasePrice) {
            <span> {{ basePrice | formatDecimal: 2 }} &nbsp; </span>
          } @else {
            <span class="spinner-border spinner-border-sm" role="status"></span>
          }
          (CHF/m<sup>3</sup>)
        </div>
        <div class="col-md-12 mb-3 d-flex align-items-center">
          <button
            class="btn button-medium mt-3"
            type="button"
            (click)="updatePricingParameters()"
            [disabled]="disableButtonsAdvisor"
          >
            Parameter updaten
            @if (updatingPricingParameters) {
              <div>
                <div class="spinner-border spinner-border-sm" role="status"></div>
              </div>
            }
          </button>
          <div class="mt-3 ms-3">
            Zuletzt geändert:
            {{ pricingParametersLatest?.datetime_created | date: 'dd.MM.yyyy HH:mm:ss' }}
          </div>
        </div>
      </div>

      <div class="border rounded p-3 mt-3">
        <div class="form-group row align-items-center">
          <label class="col-md-8 col-form-label mt-2">Dünger Marktpreis</label>
        </div>
        <div class="col-md-12 mb-3 d-flex align-items-center">
          @for (parameter of fertilizerMarketPrices; track parameter) {
          <div>
            <button
              class="btn mt-3 price-button"
              [ngClass]="{
                'btn-primary': fertilizerMarket === parameter && !updatingFertilizerMarketPrice,
                'btn-outline-primary':
                  fertilizerMarket !== parameter || updatingFertilizerMarketPrice
              }"
              type="button"
              (click)="onFertilizerMarketChange(parameter)"
              [disabled]="disableButtonsAdvisor || updatingFertilizerMarketPrice || disableButtons"
            >
              {{ parameter | translateFertilzerMarketPrice }}
              @if (updatingFertilizerMarketPrice && loadingIconFertilizerMarketPrice === parameter)
              {
              <div>
                <div class="spinner-border spinner-border-sm" role="status"></div>
              </div>
              }
            </button>
          </div>
          }
        </div>
      </div>

      <div class="border rounded p-3 mt-3">
        <div class="form-group row align-items-center">
          <label class="col-md-8 col-form-label mt-2">
            Basispreis (CHF/m<sup>3</sup>) für die nächsten {{ this.period }} Tage</label
          >
          <div class="col-md-4 d-flex align-items-center">
            <input
              type="number"
              [(ngModel)]="basePriceNew"
              name="base_price"
              class="form-control ms-3"
            />
          </div>
        </div>
        <div class="col-md-12 mb-3 d-flex align-items-center">
          <button
            class="btn button-medium mt-3"
            type="button"
            (click)="setBasePrice()"
            [disabled]="disableButtonsAdvisor"
          >
            Basispreis updaten
            @if (updatingBasePrice) {
              <div>
                <div class="spinner-border spinner-border-sm" role="status"></div>
              </div>
            }
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="border rounded p-3">
        <div class="form-group row align-items-center">
          <label class="col-md-8 col-form-label mt-2"> Momentaner Lagerstand (m<sup>3</sup>)</label>
          <div class="col-md-4 d-flex align-items-center">
            <input
              type="number"
              [(ngModel)]="currentStock.level_measured"
              name="current_stock"
              class="form-control ms-3"
            />
          </div>
        </div>
        <div class="col-md-12 mb-3 d-flex align-items-center justify-content-between">
          <button
            class="btn button-medium mt-3"
            type="button"
            (click)="setCurrentStock()"
            [disabled]="disableButtons"
          >
            Lagerbestand updaten
            @if (updatingStock) {
              <div>
                <div class="spinner-border spinner-border-sm" role="status"></div>
              </div>
            }
          </button>
          <div class="mt-3 ms-3">
            Zuletzt verwendeter Wert (m<sup>3</sup>): &nbsp;
            {{
              currentStock.level_last_used
                ? (currentStock.level_last_used | formatDecimal: 0)
                : 'N/A'
            }}
          </div>
        </div>
      </div>
      <div class="border rounded p-3 mt-3">
        <div class="form-group row align-items-center">
          <label class="col-md-8 col-form-label mt-2"> Unterer Schwellenwert (m<sup>3</sup>)</label>
          <div class="col-md-4 d-flex align-items-center">
            <input
              type="number"
              [(ngModel)]="stockParameters.threshold_lower"
              name="thresh_lower"
              class="form-control ms-3"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-md-8 col-form-label mt-2"> Oberer Schwellenwert (m<sup>3</sup>)</label>
          <div class="col-md-4 d-flex align-items-center">
            <input
              type="number"
              [(ngModel)]="stockParameters.threshold_upper"
              name="thresh_upper"
              class="form-control ms-3"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-md-8 col-form-label mt-2"> Lagerkapazität (m<sup>3</sup>)</label>
          <div class="col-md-4 d-flex align-items-center">
            <input
              type="number"
              [(ngModel)]="stockParameters.capacity"
              name="capacity"
              class="form-control ms-3"
            />
          </div>
        </div>
        <div class="col-md-12 mb-3 d-flex align-items-center">
          <button
            class="btn button-medium mt-3"
            type="button"
            (click)="setStockParams()"
            [disabled]="disableButtons"
          >
            Lagerparameter updaten
            @if (updatingStockParameters) {
              <div>
                <div class="spinner-border spinner-border-sm" role="status"></div>
              </div>
            }
          </button>
        </div>
      </div>
      <div class="border rounded p-3 mt-3">
        <div class="d-flex align-items-center justify-content-between">
          <div>Tägliche Produktionsmenge (m<sup>3</sup>)</div>
          <div class="d-flex align-items-center justify-content-end">
            <div class="col-md-4 me-0 text-small-bold">Wochen 1 - 52</div>
            <div class="col-md-2 ms-1">
              <input
                type="text"
                class="form-control form-control-sm text-small p-lg-1"
                [(ngModel)]="dailyProductionUpdateAll"
              />
            </div>
            <div class="col-md-2 ms-1">
              <button
                class="btn button-update bi-arrow-repeat"
                (click)="updateProductionVolumeAll()"
              ></button>
            </div>
          </div>
        </div>
        @for (chunk of displayChunks; track $index) {
          <table class="table table-responsive table-bordered mt-3">
            <thead>
              <tr class="text-small">
                <th>Woche</th>
                @for (i of chunk; track $index) {
                  <th>{{ dailyProduction[i].week + 1 }}</th>
                }
              </tr>
            </thead>
            <tbody>
              <tr class="text-small">
                <td>Menge</td>
                @for (i of chunk; track $index) {
                  <td>
                    <input
                      type="text"
                      [(ngModel)]="dailyProduction[i].value"
                      class="form-control form-control-sm text-small p-lg-1"
                    />
                  </td>
                }
              </tr>
            </tbody>
          </table>
        }
        <div class="col-md-12 mb-3 d-flex align-items-center">
          <button
            class="btn button-medium mt-3"
            type="button"
            (click)="setProductionVolume()"
            [disabled]="disableButtons"
          >
            Produktionsmenge updaten
            @if (updatingProductionVolume) {
              <div>
                <div class="spinner-border spinner-border-sm" role="status"></div>
              </div>
            }
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

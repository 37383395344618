import { Injectable } from '@angular/core';
import { StatusEnum } from '../../shared/types/api_models/order';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  private status = {
    ORDERED: 'Bestellt',
    READY: 'Abholbereit',
    INPROGRESS: 'Abholungsprozess läuft',
    DELAYED: 'Verspätet',
    COMPLETE: 'Abholung vollständig',
    CANCELLED: 'Storniert',
  };

  translate(status: StatusEnum): string {
    return this.status[status] || status;
  }
}

<div class="container mt-4" xmlns="http://www.w3.org/1999/html">
  <div class="heading">Naturdünger bestellen</div>
  <div class="container fixed-width">
    <div class="input-form-element">
      <div class="input-textbox">
        <div class="text-large">Produkt wählen</div>
        <div>
          <!-- TODO: for multiple plants, the pdfs should be stored in a SA and selected based on the plant -->
          <a class="data-sheet-link text-regular" href="assets/Factsheet2025.pdf" target="_blank">
            <div>Datenblatt</div>
            <div class="icon-container">
              <i class="bi bi-box-arrow-up-right link-icon"></i>
            </div>
          </a>
        </div>
      </div>
      <div class="input-group" [formGroup]="form">
        <select class="product form-select" formControlName="selectedProduct">
          @for (name of productNames; track $index) {
            <option value="{{ productIds[$index] }}">{{ name }}</option>
          }
        </select>
      </div>
    </div>

    <div class="input-form-element">
      <div class="input-textbox text-large">
        Abholzeitraum
        <button
          class="btn btn-link button-info p-1 bi bi-info-circle"
          (click)="openDateModal()"
        ></button>
      </div>
      <div class="input-group">
        <app-datepicker-range
          (fromDateEvent)="setFromDate($event)"
          (toDateEvent)="setToDate($event)"
          [minSelectableDate]="dateToday"
          [maxSelectableDate]="datePeriodEnd"
          [maxSelectablePeriod]="maxPickupPeriod"
        ></app-datepicker-range>
      </div>
    </div>

    <div class="input-form-element">
      <div class="input-textbox text-large">
        Menge in m<sup>3</sup>
        <button
          class="btn btn-link button-info p-1 bi bi-info-circle"
          (click)="openAmountModal()"
        ></button>
      </div>
      <div class="input-group" [formGroup]="form">
        <input type="number" class="form-control" formControlName="amount" min="1" max="500" />
      </div>
    </div>

    <div class="input-form-element">
      <div class="input-textbox text-large">Abholart</div>
      <div class="input-group" [formGroup]="form">
        <div class="form-check d-flex align-items-center">
          <div class="d-flex align-items-center">
            <input
              class="form-check-input ms-3"
              type="radio"
              formControlName="deliveryType"
              [value]="DeliveryEnum.PICKUP"
              id="radioPickup"
            />
            <label class="form-check-label ms-2 d-flex align-items-center" for="radioPickup">
              Selbstabholung
            </label>
          </div>
          <div class="d-flex align-items-center">
            <input
              class="form-check-input ms-5"
              type="radio"
              formControlName="deliveryType"
              [value]="DeliveryEnum.DELIVERY"
              id="radioDelivery"
            />
            <label class="form-check-label ms-2 d-flex align-items-center" for="radioDelivery">
              Lieferung
              <button
                class="btn btn-link button-info bi bi-info-circle"
                (click)="openDeliveryModal()"
              ></button>
            </label>
          </div>
        </div>
      </div>
    </div>

    @if (isSeller) {
      <div class="input-form-element justify-content-between">
        <div class="col-4">
          <div class="text-large">
            Gutschrift
            <button
              class="btn btn-link button-info p-1 bi bi-info-circle"
              (click)="openPriceModal()"
            ></button>
          </div>
        </div>
        <div class="col-8">
          @if (!loading && !isError && isAvailable) {
            <div class="price">
              {{ (price?.sales_price_per_m3 ? price!.sales_price_per_m3 : 0.0) | formatDecimal: 2 }}
              CHF/m<sup>3</sup>
            </div>
            <div class="price-sub mt-1">
              Gesamt
              {{ (price?.sales_price ? price!.sales_price : 0.0) | formatDecimal: 2 }} CHF
            </div>
          }
          @if (loading) {
            <div>
              <div class="spinner-border spinner-price" role="status"></div>
            </div>
          }
          @if (isError) {
            <div>
              <div class="error-bold">Preis konnte nicht geladen werden</div>
            </div>
          }
          @if (!isAvailable) {
            <div>
              <span class="error-bold">Gewünschte Menge nicht verfügbar <br /></span>
              <span class="error" [innerHTML]="availabilityMsg"></span>
            </div>
          }
        </div>
      </div>
    }

    <div style="margin-top: 20px">
      <button
        class="btn button-medium"
        type="button"
        [disabled]="disableOrderButton"
        (click)="openConfirmationModal()"
      >
        Bestellen
        @if (ordering) {
          <div>
            <div class="spinner-border spinner-border-sm spinner-button" role="status"></div>
          </div>
        }
      </button>
    </div>
  </div>
</div>

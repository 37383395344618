import { AfterViewInit, Component, HostListener, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import * as Leaflet from 'leaflet';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';
import { Geocoder, geocoder, geocoders } from 'leaflet-control-geocoder';
import { Circle, CircleMarker, latLng } from 'leaflet';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { IPlant } from '../../shared/types/api_models/plant';
import { ApiService } from '../../core/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MarkGeocodeEvent } from 'leaflet-control-geocoder/dist/control';

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, LeafletModule],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss',
})
export class WelcomeComponent implements OnInit, AfterViewInit {
  plants: IPlant[] | undefined;
  location: CircleMarker | undefined;
  markerFertilizerLiquid: Circle | undefined;
  markerFertilizerSolid: Circle | undefined;
  radiusFertilizerLiquid: number = 20000; // in meters
  radiusFertilizerSolid: number = 40000;
  showLegend: boolean = false;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toast: ToastrService,
  ) {}

  map!: Leaflet.Map;
  markers: Leaflet.Marker[] = [];
  options = {
    zoom: 8,
    center: latLng(47.05048, 8.30635),
    layers: [
      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '',
      }),
    ],
    attributionControl: false,
  };

  form: any;
  isMobile: boolean = false;

  ngOnInit() {
    this.setDisplay();
  }

  async ngAfterViewInit() {
    try {
      this.apiService.getPlants().subscribe((plants: IPlant[]) => {
        this.plants = plants;
        this.initMarkers(this.plants);
      });
    } catch {
      this.plants = [];
    }
  }

  initMarkers(plants: IPlant[]) {
    for (let plant of plants) {
      const data = {
        position: { lat: plant.latitude, lng: plant.longitude },
        draggable: false,
      };
      const marker =
        plant.name === 'Axpo Biomasse AG Jona'
          ? this.generateMarker(data, '#CC0000')
          : this.generateMarker(data);
      marker.addTo(this.map).bindPopup(`<b>${plant.name}</b>`);
      this.markers.push(marker);
    }
  }

  generateMarker(data: any, color: string = '#000000') {
    const customIcon = Leaflet.divIcon({
      className: 'custom-icon',
      html: `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill=${color} class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
              </svg>`,
      iconSize: [30, 30],
      iconAnchor: [15, 15],
    });
    return Leaflet.marker(data.position, { icon: customIcon }).on('click', event =>
      this.markerClicked(event),
    );
  }

  onMapReady($event: Leaflet.Map) {
    this.map = $event;
    this.map.on('locationfound', this.onLocationFound, this);
    this.map.on('locationerror', this.onLocationError, this);
    const geocoder = new Geocoder({
      geocoder: geocoders.nominatim({ geocodingQueryParams: { countrycodes: 'ch' } }),
      defaultMarkGeocode: false,
      placeholder: 'Nach Ort suchen...',
    });
    geocoder.on('markgeocode', (e: MarkGeocodeEvent) => {
      if (this.location) {
        this.map.removeLayer(this.location);
      }
      this.location = Leaflet.circleMarker(e.geocode.center, { radius: 5 }).addTo(this.map);
      this.removeRadii();
      this.map.flyTo(e.geocode.center, 10);
    });
    geocoder.addTo(this.map);
  }

  onLocationClick() {
    this.map.locate({ setView: true, maxZoom: 10 });
  }

  onLocationFound(e: Leaflet.LocationEvent) {
    if (this.location) {
      this.map.removeLayer(this.location);
    }
    this.location = Leaflet.circleMarker(e.latlng, { radius: 5 }).addTo(this.map);
    this.removeRadii();
  }

  onLocationError(e: Leaflet.ErrorEvent) {
    this.toast.error('Location error: ' + e.message);
  }

  onRadiusClick() {
    if (!this.location?.getLatLng()) {
      return;
    }
    this.showLegend = true;
    if (this.markerFertilizerLiquid && this.markerFertilizerSolid) {
      this.removeRadii();
      return;
    }
    this.markerFertilizerLiquid = Leaflet.circle(this.location?.getLatLng(), {
      radius: this.radiusFertilizerLiquid,
      fillOpacity: 0,
      color: '#D16002',
    }).addTo(this.map);
    this.markerFertilizerSolid = Leaflet.circle(this.location?.getLatLng(), {
      radius: this.radiusFertilizerSolid,
      fillOpacity: 0,
      color: '#0B6623',
    }).addTo(this.map);
  }

  private removeRadii() {
    this.showLegend = false;
    if (!this.markerFertilizerLiquid || !this.markerFertilizerSolid) {
      return;
    }
    this.map.removeLayer(this.markerFertilizerLiquid);
    this.map.removeLayer(this.markerFertilizerSolid);
    this.markerFertilizerLiquid = undefined;
    this.markerFertilizerSolid = undefined;
  }

  mapClicked($event: any) {
    console.info($event.latlng.lat, $event.latlng.lng);
  }

  markerClicked($event: any) {
    console.info($event.latlng.lat, $event.latlng.lng);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.setDisplay();
  }

  setDisplay() {
    this.isMobile = window.innerWidth < 768;
  }

  navigateToPlant() {
    this.router.navigate([`/product-order`]);
  }
}

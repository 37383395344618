import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, map } from 'rxjs';
import { IUser } from '../../shared/types/api_models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private oidcSecurityService: OidcSecurityService) {}

  get user$(): Observable<IUser> {
    return this.oidcSecurityService.getUserData().pipe(
      map(data => {
        let user = {} as IUser;
        user.external_id = data.sub;
        user.email = data.email;
        user.first_name = data.given_name;
        user.last_name = data.family_name;
        user.phone = data.phone;
        return user;
      }),
    );
  }
}

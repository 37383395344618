import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../core/services/api.service';
import { Router } from '@angular/router';
import { IUser, IUserRead } from '../../shared/types/api_models/user';

@Component({
  selector: 'app-buyer-overview',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './buyer-overview.component.html',
  styleUrl: './buyer-overview.component.scss',
})
export class BuyerOverviewComponent implements OnInit {
  buyers: IUserRead[] | undefined;

  constructor(private apiService: ApiService) {}

  async ngOnInit() {
    try {
      this.apiService.getUsers().subscribe(data => {
        this.buyers = data;
      });
    } catch {
      this.buyers = [];
    }
  }
}

<div class="container-sm mt-4">
  <div class="row">
    <div class="col-md-5">
      <div class="heading">Abholung erfassen</div>
    </div>
    <div class="col-md-7 button-container">
      <button class="btn button-medium-inverted" type="button" (click)="onBack()">
        Zurück zur Bestellung
      </button>
    </div>
  </div>

  @if (loading) {
    <div class="spinner-overlay spinner-border spinner" role="status"></div>
  }
  <div class="row">
    <div class="col-md-8">
      <div class="border rounded mt-3 p-3">
        <form [formGroup]="pickupForm" (ngSubmit)="updatePickups()">
          <div class="input-form-element pb-3">
            <div class="input-textbox text-large">Datum der Abholung</div>
            <input
              type="date"
              formControlName="date"
              class="form-control"
              (input)="compareDate()"
            />
            @if (timeInvalid) {
              <div class="input-small form-text-error mt-1 mb-1">
                Das Datum liegt ausserhalb des angegebenen Abholzeitraums.
              </div>
            }
          </div>

          <div class="input-form-element pb-3">
            <div class="input-textbox text-large mt-2">Menge in m<sup>3</sup></div>
            <input
              type="number"
              formControlName="amount"
              class="form-control"
              (input)="checkAmountRestrictions()"
            />
            <div class="input-small form-text-info mt-1 mb-1">
              @if (amountLeft === 1) {
                {{ amountLeft }} m<sup>3</sup> kann in dieser Bestellung noch abgeholt werden.
              } @else if (amountLeft === 0 || (amountLeft && amountLeft < 0)) {
                Abholung vollständig, bitte schliessen Sie den Abholprozess ab.
              } @else {
                {{ amountLeft }} m<sup>3</sup> können in dieser Bestellung noch abgeholt werden.
              }
            </div>
          </div>

          <div class="button-container">
            <button
              class="btn button-medium-inverted"
              [disabled]="timeInvalid || orderDefComplete"
              type="submit"
            >
              Abholung speichern
              @if (saving) {
                <div>
                  <div class="spinner-border spinner-border-sm spinner-button" role="status"></div>
                </div>
              }
            </button>
          </div>

          <div class="button-container">
            <button
              class="btn button-medium"
              type="button"
              (click)="openConfirmationFinishOrderModal()"
              [disabled]="!orderReadyToComplete || (timeInvalid && this.pickupForm.value.amount)"
            >
              Abholprozess abschliessen
            </button>

            <button
              type="button"
              class="btn button-info p-1 bi bi-info-circle"
              [ngbPopover]="
                'Sobald Sie alle Abholungen getätigt haben, können Sie den Abholprozess mit diesem Button abschliessen. '
              "
              [placement]="'auto'"
            ></button>
          </div>
        </form>
      </div>
      <div class="border rounded mt-3 p-3">
        <h2>Bereits getätigte Abholungen</h2>
        <table class="table table-hover" style="margin-top: 20px">
          <thead>
            <tr>
              <th scope="col">Erfassungsdatum</th>
              <th scope="col">Datum</th>
              <th scope="col">Menge in m<sup>3</sup></th>
            </tr>
          </thead>
          @if (!loading) {
            <tbody>
              @for (pickup of pickups; track pickup.id) {
                <tr>
                  <td>{{ pickup.datetime_created | date: 'dd.MM.yyyy HH:mm' }}</td>
                  <td>{{ pickup.date | date: 'dd.MM.yyyy' }}</td>
                  <td>{{ pickup.amount }}</td>
                </tr>
              } @empty {
                <div class="mt-1">Es wurde noch nichts abgeholt.</div>
              }
            </tbody>
          }
        </table>
      </div>
    </div>
    <div class="col-md-4">
      <div class="border rounded mt-3 p-3">
        <h3>Bestelldetails</h3>
        <table class="table mt-3">
          @if (!loading) {
            @if (order) {
              <tbody>
                <tr>
                  <td>Bestellnummer</td>
                  <td>{{ order.id }}</td>
                </tr>
                <tr>
                  <td>Käufer</td>
                  <td>{{ order.buyer__first_name }} {{ order.buyer__last_name }}</td>
                </tr>
                <tr>
                  <td>Anlage</td>
                  <td>{{ order.plant__name }}</td>
                </tr>
                <tr>
                  <td>Produkt</td>
                  <td>{{ order.product__name }}</td>
                </tr>
                <tr>
                  <td>Menge m<sup>3</sup></td>
                  <td>{{ order.amount }}</td>
                </tr>
                <tr>
                  <td>Bereits abgeholte Menge</td>
                  <td>
                    {{ pickupSum }}
                  </td>
                </tr>
                <tr>
                  <td>Abholzeitraum</td>
                  <td>
                    {{ order.pickup_start_date | date: 'dd.MM.yyyy' }} -
                    {{ order.pickup_end_date | date: 'dd.MM.yyyy' }}
                  </td>
                </tr>
              </tbody>
            } @else {
              Bestellung kann nicht angezeigt werden.
            }
          }
        </table>
      </div>
    </div>
  </div>
</div>

import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs';
import { ApiService } from '../../core/services/api.service';
import { IButtonOptions, ModalService } from '../../core/services/modal.service';
import { DatepickerRangeComponent } from '../../shared/components/datepicker-range/datepicker-range.component';
import { IAvailability } from '../../shared/types/api_models/availability';
import { DeliveryEnum, IOrderCreate, StatusEnum } from '../../shared/types/api_models/order';
import { ISalesPrice } from '../../shared/types/api_models/price';
import { IProduct } from '../../shared/types/api_models/product';
import { UserService } from '../../core/services/user.service';
import { FormatDecimalPipe } from '../../core/pipes/format-decimal.pipe';
import { RoleService } from '../../core/services/role.service';

@Component({
  selector: 'app-product-order',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbInputDatepicker,
    DatepickerRangeComponent,
    FormatDecimalPipe,
  ],
  templateUrl: './product-order.component.html',
  styleUrl: './product-order.component.scss',
})
export class ProductOrderComponent implements OnInit {
  form = new FormGroup({
    amount: new FormControl<number>(0, [Validators.required, this.amountValidator]),
    selectedProduct: new FormControl<number>(0, [Validators.required]),
    fromDate: new FormControl<string>('', [Validators.required]),
    toDate: new FormControl<string>('', [Validators.required]),
    deliveryType: new FormControl<DeliveryEnum>(DeliveryEnum.PICKUP, [Validators.required]),
  });
  dateToday: NgbDate;
  datePeriodEnd: NgbDate;
  maxOrderInAdvancePeriod: number = 90;
  maxPickupPeriod: number = 20;
  disableOrderButton = true;
  loading = false;
  isError = false;
  isAvailable = true;
  ordering = false;
  availabilityMsg = '';
  errorMsg = '';
  availability: IAvailability | undefined;
  price: ISalesPrice | undefined;
  orderId: any;
  products: IProduct[] = [];
  productIds: number[] = [];
  productNames: string[] = [];
  productIdSelected: number = 1;
  isSeller: boolean = false;

  constructor(
    private userService: UserService,
    private apiService: ApiService,
    private toast: ToastrService,
    private calendar: NgbCalendar,
    public datePipe: DatePipe,
    public decimalPipe: DecimalPipe,
    private modalService: ModalService,
    private router: Router,
    private roleService: RoleService,
  ) {
    this.dateToday = this.calendar.getToday();
    this.datePeriodEnd = this.calendar.getNext(this.dateToday, 'd', this.maxOrderInAdvancePeriod);
    this.form.valueChanges.pipe(debounceTime(300)).subscribe(async value => {
      if (this.form.valid) {
        this.disableOrderButton = true;
        this.isError = false;
        this.loading = true;
        this.isAvailable = true;
        this.toast.clear();
        this.productIdSelected = Number(this.form.value['selectedProduct']!);
        this.apiService
          .checkAvailability(
            this.productIdSelected,
            value['amount']!,
            value['fromDate']!,
            value['toDate']!,
          )
          .subscribe({
            next: (availability: IAvailability) => {
              this.availability = availability;
              if (!this.availability || !this.availability.available) {
                this.isAvailable = false;
                this.loading = false;
                this.disableOrderButton = true;
                this.availabilityMsg =
                  this.availability?.proposed_solution.replace(/\n/g, '</br>') || '';
              } else {
                this.apiService
                  .getSalesPrice(
                    this.productIdSelected,
                    value['amount']!,
                    value['fromDate']!,
                    value['toDate']!,
                  )
                  .subscribe({
                    next: (price: ISalesPrice) => {
                      this.price = price;
                      this.disableOrderButton = false;
                      this.loading = false;
                    },
                    error: (error: any) => {
                      this.isError = true;
                      this.errorMsg = error.message;
                      this.loading = false;
                    },
                  });
              }
            },
            error: (error: any) => {
              this.isError = true;
              this.errorMsg = error.message;
              this.loading = false;
            },
          });
      } else {
        if (
          !this.form.get('amount')?.pristine &&
          this.form.get('amount')?.hasError('AmountOutOfBounds') &&
          this.form.get('amount')?.value !== null
        ) {
          this.toast.error('Die Menge muss zwischen 1 und 500 liegen');
        }
        if (this.form.get('fromDate')?.hasError('required')) {
          this.toast.error('Bitte wählen Sie ein Startdatum');
        }
        if (!this.form.get('toDate')?.pristine && this.form.get('toDate')?.hasError('required')) {
          this.toast.error('Bitte wählen Sie ein Enddatum');
        }
      }
    });
    this.roleService.isSeller$.subscribe(isSeller => {
      this.isSeller = isSeller;
    });
  }

  async ngOnInit() {
    this.apiService.getProducts().subscribe(products => {
      this.products = products;

      this.productIds = this.products.map(product => product.id);
      this.productNames = this.products.map(product => product.name);

      if (this.productIds.length === 0) {
        return;
      }

      this.productIdSelected = this.productIds[0];
      this.form.patchValue({ selectedProduct: this.productIdSelected });
    });

    this.form.get('amount')?.valueChanges.subscribe(value => {
      this.checkIfAboveLimit(value as number);
    });
  }

  private checkIfAboveLimit(value: number): void {
    this.disableOrderButton = value < 1 || value > 500;
  }

  amountValidator(input: any) {
    const value = input.value;
    if (value && value > 0 && value <= 500) {
      return null;
    }
    return { AmountOutOfBounds: true };
  }

  createOrder = async () => {
    this.ordering = true;
    if (!this.price) {
      throw new Error('Could not create order, price not found.');
    }
    let order = {} as IOrderCreate;
    this.userService.user$.subscribe(user => {
      order.buyer_external_id = user.external_id;
    });
    order.plant_id = this.products[this.productIds.indexOf(this.productIdSelected)].plant__id;
    order.product_id = this.productIdSelected;
    order.sales_price_id = this.price.id;
    order.amount = this.form.value['amount']!;
    order.pickup_start_date = this.form.value['fromDate']!;
    order.pickup_end_date = this.form.value['toDate']!;
    order.delivery_option = this.form.value['deliveryType']!;
    const start_date = new Date(order.pickup_start_date);
    order.status = start_date <= new Date() ? StatusEnum.READY : StatusEnum.ORDERED;
    this.apiService.createOrder(order).subscribe({
      next: orderResult => {
        this.ordering = false;
        this.price = undefined;
        this.disableOrderButton = true;
        this.orderId = orderResult.id;
        this.openSuccessModal();
        this.apiService.recalculateStock(this.productIdSelected).subscribe({
          next: res => {},
          error: error => {
            this.toast.error('Lagerberechnung fehlgeschlagen');
          },
        });
      },
      error: error => {
        this.ordering = false;
        this.toast.error('Bestellung fehlgeschlagen');
      },
    });
  };

  async setFromDate(date: NgbDate | null) {
    if (date) {
      this.form.patchValue({ fromDate: this.convertNgbDate(date) });
    } else {
      this.form.patchValue({ fromDate: '' });
    }
  }

  async setToDate(date: NgbDate | null) {
    if (date) {
      this.form.patchValue({ toDate: this.convertNgbDate(date) });
    } else {
      this.form.patchValue({ toDate: '' });
    }
  }

  convertNgbDate(ngbDate: NgbDate): string {
    const date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    const date_string = this.datePipe.transform(date, 'yyyy-MM-dd');
    return date_string != null ? date_string : '';
  }

  openConfirmationModal() {
    const deliverOptionTranslated =
      this.form.value['deliveryType'] === DeliveryEnum.PICKUP ? 'Selbstabholung' : 'Lieferung';
    const options: IButtonOptions = {
      title: 'Bestellung bestätigen',
      body: '',
      id: this.orderId,
      buttonText: 'Definitiv bestellen',
      closeButtonText: 'Zurück',
      htmlContent: `<div>
            <div>
                <div class="d-flex justify-content-between">
                    <div>Produkt:</div>
                    <div>${
                      this.products[this.productIds.indexOf(this.productIdSelected)].name
                    }</div>
                </div>
                <div class="d-flex justify-content-between">
                    <div>Menge:</div>
                    <div>${this.form.value['amount']} m<sup>3</sup></div>
                </div>
                <div class="d-flex justify-content-between">
                    <div>Abholzeitraum:</div>
                    <div>${this.form.value['fromDate']} - ${this.form.value['toDate']}</div>
                </div>
                <div class="d-flex justify-content-between">
                    <div>Abholart:</div>
                    <div> ${deliverOptionTranslated} </div>
                </div>
                ${
                  this.isSeller
                    ? `<div class="d-flex justify-content-between">
                  <div>Gutschrift:</div>
                  <div>${this.decimalPipe.transform(this.price?.sales_price, '1.1-2')} CHF</div>
              </div>`
                    : ``
                }

            </div>
            <div class="mt-4">
                <div>
                    <b>Wollen Sie die Bestellung definitiv aufgeben?</b>
                </div>
            </div>
        </div>`,
      buttonAction: () => this.createOrder(),
    };
    this.modalService.openModal(options);
  }

  openSuccessModal() {
    const options: IButtonOptions = {
      title: 'Bestellung erfolgreich',
      body: 'Die Bestellung wurde erfolgreich aufgegeben.',
      id: this.orderId,
      buttonText: 'Zur Bestellung',
      buttonAction: () => this.navigateToOrderDetailPage(),
    };
    this.modalService.openModal(options);
  }

  openDateModal() {
    const options = {
      title: 'Informationen zum Abholzeitraum',
      body: '',
      htmlContent: this.isSeller
        ? `<div>
            Die Bestellung kann zwischen 0 - 90 Tagen im Voraus aufgegeben werden und wirkt sich wie folgt auf die
            Gutschrift aus:
        </div>
        <div class="ms-5 mt-1">
            <li>0 - 7 Tage im Voraus: tiefere Gutschrift</li>
            <li>8 - 90 Tage im Voraus: höhere Gutschrift</li>
        </div>
        <div class="mt-4">
            Die Bezugszeitspanne liegt zwischen 1 - 20 Tagen und wirkt sich wie folgt auf die Gutschrift aus:
            <div class="ms-5 mt-1">
              <li>1 - 7 Tage Bezug: höhere Gutschrift</li>
              <li>1 - 20 Tage Bezug: tiefere Gutschrift</li>
          </div>
        </div>`
        : ` <div>
        Die Bestellung kann zwischen 0 - 90 Tagen im Voraus aufgegeben werden. Die Bezugszeitspanne liegt zwischen 1 - 20 Tagen
    </div>`,
    };
    this.modalService.openModal(options);
  }

  openAmountModal() {
    const options = {
      title: 'Informationen zur Menge',
      body: '',
      htmlContent: this.isSeller
        ? `<div>
            Die bestellbare Menge kann maximal 500 m<sup>3</sup> betragen und wirkt sich wie folgt auf die Gutschrift
            aus:
            <div class="ms-5 mt-1">
              <li>0 - 50 m<sup>3</sup>: tiefere Gutschrift</li>
              <li>51 - 300 m<sup>3</sup>: mittlere Gutschrift</li>
              <li>301 - 500 m<sup>3</sup>: höhere Gutschrift</li>
          </div>
        </div>`
        : `<div>Die bestellbare Menge kann maximal 500 m<sup>3</sup> betragen.</div>`,
    };
    this.modalService.openModal(options);
  }

  openPriceModal() {
    const options = {
      title: 'Informationen zur Gutschrift',
      body: '',
      htmlContent: `<div>
            Neben Abholzeitraum und Menge haben folgende Faktoren ebenfalls einen Einfluss auf die Höhe der Gutschrift:
            <div class="ms-5 mt-1">
              <li>Lagerbestand der Anlage</li>
              <li>Produktionsvolumen</li>
              <li>Landwirtschaftlicher Zyklus</li>
              <li>Düngerpreise</li>
          </div>
        </div>`,
    };
    this.modalService.openModal(options);
  }

  openDeliveryModal() {
    const options = {
      title: 'Informationen zur Lieferung',
      body: '',
      htmlContent: this.isSeller
        ? `<div>
            Falls eine Lieferung gewünscht ist, wird diese für Sie organisiert und die Kosten werden der
            angezeigten Gutschrift nachträglich abgezogen. Für weitere Details kontaktieren Sie uns bitte.
        </div>`
        : `<div>
            Bei einer durch Axpo organisierten Lieferung können zusätzliche Kosten entstehen. Für weiter Details kontaktieren Sie uns bitte.
        </div>`,
    };
    this.modalService.openModal(options);
  }

  navigateToOrderDetailPage() {
    this.router.navigate([`/order-detail/buyer/${this.orderId}`]);
  }

  protected readonly DeliveryEnum = DeliveryEnum;
}

<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid">
    <button
      class="navbar-toggler border-0"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarToggle"
      aria-controls="navbarToggle"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="ms-auto dropdown-wrapper order-lg-last">
      <app-user-dropdown
        class="me-1"
        (sellerViewEvent)="setSellerView($event)"
        [isSellerView]="isSellerView"
      ></app-user-dropdown>
    </div>
    <div class="collapse navbar-collapse" id="navbarToggle">
      <a class="navbar-brand d-none d-lg-block" routerLink="/welcome">
        <img src="favicon.ico" alt="NAFET" width="30" />
      </a>
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        @if (!isSellerView) {
          <li class="nav-item navigation-tab">
            <a class="nav-link" routerLink="/product-order" routerLinkActive="active-link"
              >Bestellen</a
            >
          </li>
        }
        @if (!isSellerView) {
          <li class="nav-item navigation-tab">
            <a
              class="nav-link"
              [routerLink]="['/order-overview/buyer']"
              routerLinkActive="active-link"
              >Meine Bestellungen</a
            >
          </li>
        }
        @if (!isSellerView) {
          <li class="nav-item navigation-tab">
            <!-- Added hardcoded 13 (niederuzwil in prod db). Needs to be removed at some point.-->
            <a class="nav-link" routerLink="/contact/13" routerLinkActive="active-link">Kontakt</a>
          </li>
        }
        @if (!isSellerView) {
          <li class="nav-item navigation-tab">
            <a class="nav-link" [routerLink]="['/impressum']" routerLinkActive="active-link"
              >Impressum</a
            >
          </li>
        }
        @if (isSellerView) {
          <li class="nav-item navigation-tab">
            <a class="nav-link" routerLink="/cockpit" routerLinkActive="active-link">Cockpit</a>
          </li>
        }
        @if (isSellerView) {
          <li class="nav-item navigation-tab">
            <a class="nav-link" routerLink="/price-and-stock" routerLinkActive="active-link"
              >Preis und Verfügbarkeit</a
            >
          </li>
        }
        @if (isSellerView) {
          <li class="nav-item navigation-tab">
            <a
              class="nav-link"
              [routerLink]="['/order-overview/seller']"
              routerLinkActive="active-link"
              >Bestellübersicht</a
            >
          </li>
        }
        @if (isSellerView) {
          <li class="nav-item navigation-tab">
            <a class="nav-link" routerLink="/buyers" routerLinkActive="active-link">Kundendaten</a>
          </li>
        }
      </ul>
      <location-dropdown class="me-2" />
    </div>
  </div>
</nav>

import { Routes } from '@angular/router';
import { autoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { CallbackComponent } from './core/components/callback/callback.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { UnauthorizedComponent } from './core/components/unauthorized/unauthorized.component';
import { sellerGuard } from './core/guards/seller.guard';
import { BuyerOverviewComponent } from './modules/buyer-overview/buyer-overview.component';
import { CockpitComponent } from './modules/cockpit/cockpit.component';
import { ContactComponent } from './modules/contact/contact.component';
import { OrderDetailComponent } from './modules/order-detail/order-detail.component';
import { OrderOverviewComponent } from './modules/order-overview/order-overview.component';
import { PickUpComponent } from './modules/pick-up/pick-up.component';
import { PricingComponent } from './modules/pricing/pricing.component';
import { ProductOrderComponent } from './modules/product-order/product-order.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { subdomainRedirectGuard } from './core/guards/subdomain-redirect.guard';
import { WelcomeComponent } from './modules/welcome/welcome.component';
import { ImpressumComponent } from './modules/impressum/impressum.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [subdomainRedirectGuard],
    children: [
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: 'callback', component: CallbackComponent },
      {
        path: '',
        component: WelcomeComponent,
        canActivate: [autoLoginPartialRoutesGuard],
      },
      { path: 'welcome', component: WelcomeComponent, canActivate: [autoLoginPartialRoutesGuard] },
      {
        path: 'product-order',
        component: ProductOrderComponent,
        canActivate: [autoLoginPartialRoutesGuard],
      },
      {
        path: 'order-overview/buyer',
        component: OrderOverviewComponent,
        canActivate: [autoLoginPartialRoutesGuard],
      },
      {
        path: 'order-overview/seller',
        component: OrderOverviewComponent,
        canActivate: [autoLoginPartialRoutesGuard, sellerGuard],
      },
      {
        path: 'order-detail/:overviewType/:orderId',
        component: OrderDetailComponent,
        canActivate: [autoLoginPartialRoutesGuard],
      },
      {
        path: 'order-detail/:overviewType/:orderId/pick-up',
        component: PickUpComponent,
        canActivate: [autoLoginPartialRoutesGuard],
      },
      {
        path: 'contact/:plantId',
        component: ContactComponent,
        canActivate: [autoLoginPartialRoutesGuard],
      },
      {
        path: 'price-and-stock',
        component: PricingComponent,
        canActivate: [autoLoginPartialRoutesGuard, sellerGuard],
      },
      {
        path: 'cockpit',
        component: CockpitComponent,
        canActivate: [autoLoginPartialRoutesGuard, sellerGuard],
      },
      { path: 'profile', component: ProfileComponent, canActivate: [autoLoginPartialRoutesGuard] },
      {
        path: 'buyers',
        component: BuyerOverviewComponent,
        canActivate: [autoLoginPartialRoutesGuard, sellerGuard],
      },
      {
        path: 'impressum',
        component: ImpressumComponent,
        canActivate: [autoLoginPartialRoutesGuard],
      },
      { path: '**', pathMatch: 'full', component: NotFoundComponent },
    ],
  },
];

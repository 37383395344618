import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDecimal',
  standalone: true,
})
export class FormatDecimalPipe implements PipeTransform {
  transform(value: number, digits?: number, thousandSeparator?: string): unknown {
    let value_formatted: string = value.toString();
    if (digits != undefined) {
      value_formatted = value.toFixed(digits);
    }
    if (thousandSeparator) {
      value_formatted = value_formatted.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
    }
    return value_formatted;
  }
}

import { CommonModule, DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import * as de from '@angular/common/locales/de';
import { Component, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HeaderComponent } from './core/components/header/header.component';
import { ProductOrderComponent } from './modules/product-order/product-order.component';
import { FormatDecimalPipe } from './core/pipes/format-decimal.pipe';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    ProductOrderComponent,
    HeaderComponent,
  ],
  providers: [DatePipe, DecimalPipe, FormatDecimalPipe],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isAuthenticated = false;

  constructor(private oidcSecurityService: OidcSecurityService) {
    registerLocaleData(de.default);
  }

  ngOnInit(): void {
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated }) => {
      this.isAuthenticated = isAuthenticated;
    });
  }
}

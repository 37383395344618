import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { env } from '../../../../../environments/environment';
import { RoleService } from '../../../services/role.service';

@Component({
  selector: 'app-user-dropdown',
  standalone: true,
  templateUrl: './user-dropdown.component.html',
  styleUrl: './user-dropdown.component.scss',
  imports: [CommonModule, NgbDropdownModule],
})
export class UserDropdownComponent implements OnInit {
  isSeller: boolean = false;
  @Input() isSellerView: boolean = false;
  @Output() sellerViewEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private roleService: RoleService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.roleService.isSeller$.subscribe(isSeller => {
      this.isSeller = isSeller;
    });
  }

  async onSellerViewClick() {
    this.isSellerView = !this.isSellerView;
    if (this.isSellerView) {
      await this.router.navigate(['/price-and-stock']);
    } else {
      await this.router.navigate(['/product-order']);
    }
    this.sellerViewEvent.emit(this.isSellerView);
  }

  async onProfileClick() {
    await this.router.navigate(['/profile']);
  }

  logout() {
    const logoutAuthOptions = {
      customParams: {
        client_id: env.OIDC_CLIENT_ID,
      },
    };

    this.oidcSecurityService
      .logoffAndRevokeTokens('', logoutAuthOptions)
      .subscribe(result => console.log(result));
  }
}

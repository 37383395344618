import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { isDevMode } from '@angular/core';

export const subdomainRedirectGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
) => {
  const currentDomain = window.location.hostname;
  const isSubdomain = currentDomain.startsWith('www.');
  if (isSubdomain) {
    const protocol = isDevMode() ? 'http' : 'https';
    const port = isDevMode() ? ':4200' : '';
    const mainDomain = currentDomain.replace('www.', '');
    window.location.href = `${protocol}://${mainDomain}${port}${window.location.pathname}`;
    return false;
  }
  return true;
};

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-modal',
  standalone: true,
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  imports: [CommonModule],
})
export class ModalComponent {
  @Input() title: string = '';
  @Input() body?: string = '';
  @Input() htmlContent: string | undefined;
  @Input() id?: any;
  @Input() buttonText?: string = '';
  @Input() buttonAction: Function = () => {};
  @Input() closeButtonText: string = 'Ok';

  constructor(public activeModal: NgbActiveModal) {}
}

import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  IFactors,
  IFactorsLabels,
  IPricingParametersCreate,
  IPricingParametersRead,
  IWeights,
} from '../../../shared/types/api_models/pricing_parameters';
import { ApiService } from '../../../core/services/api.service';
import { ToastrService } from 'ngx-toastr';
import {
  IStockParametersCreate,
  IStockParametersRead,
} from '../../../shared/types/api_models/stock_parameters';
import { IStockRead, IStockUpdateCreate } from '../../../shared/types/api_models/stock';
import { BehaviorSubject, catchError, forkJoin } from 'rxjs';
import { FormatDecimalPipe } from '../../../core/pipes/format-decimal.pipe';
import { RoleService } from '../../../core/services/role.service';
import { IFertilizerMarketPriceParametersCreate } from '../../../shared/types/api_models/fertilizer_market_price';
import { TranslateFertilzerMarketPrice } from '../../../core/pipes/translate-fertilizer-prices';

const PRICE_CONSTANT = { HIGH: 'HIGH', MEDIUM: 'MEDIUM', LOW: 'LOW' };

@Component({
  selector: 'app-parameters',
  standalone: true,
  imports: [CommonModule, FormsModule, NgbModule, FormatDecimalPipe, TranslateFertilzerMarketPrice],
  templateUrl: './parameters.component.html',
  styleUrl: './parameters.component.scss',
})
export class ParametersComponent implements OnInit {
  pricingParametersLatest: IPricingParametersRead | undefined;
  parameters = [
    {
      name: 'Lagerfüllstand',
      key: 'stock_level',
      weight: 1,
      percentage: 0,
      factors: { labels: [''], values: [0] },
    },
    {
      name: 'Bezugsmenge',
      key: 'order_volume',
      weight: 1,
      percentage: 0,
      factors: { labels: [''], values: [0] },
    },
    {
      name: 'Bunchungszeitpunkt',
      key: 'order_date',
      weight: 1,
      percentage: 0,
      factors: { labels: [''], values: [0] },
    },
    {
      name: 'Bezugszeitspanne',
      key: 'pickup_period',
      weight: 1,
      percentage: 0,
      factors: { labels: [''], values: [0] },
    },
    {
      name: 'Düngerpreise',
      key: 'fertilizer_market_prices',
      weight: 1,
      percentage: 0,
      factors: { labels: [''], values: [0] },
    },
    {
      name: 'Produktionsmenge',
      key: 'production_volume',
      weight: 1,
      percentage: 0,
      factors: { labels: [''], values: [0] },
    },
    {
      name: 'Wetter (landw. Zyklus)',
      key: 'agricultural_cycle',
      weight: 1,
      percentage: 0,
      factors: { labels: [''], values: [0] },
    },
  ];
  stockParameters = {} as IStockParametersRead;
  totalWeight: number = 0;
  totalPercentageCheck: number = 100;
  minMaxPrice: number[] = [0, 0];
  basePrice: number = 0;
  basePriceNew: number | undefined;
  currentStock = {} as IStockRead;
  productIds: number[] = [];
  productNames: string[] = [];
  productIdSelected$ = new BehaviorSubject<number | null>(null);
  productIdSelectedLatest: number | undefined;
  productNameSelected: string | undefined;
  period: number = 90; // TODO should be config and not re declared
  dailyProduction: { week: number; value: number }[] = [];
  dailyProductionUpdateAll: number = 0;
  displayChunks: number[][] = [[], [], [], []];
  loadingPricingParameters = true;
  loadingStockParameters = true;
  loadingStock = true;
  loadingBasePrice = true;
  loadingMinMaxPrice = true;
  updatingPricingParameters = false;
  updatingStock = false;
  updatingBasePrice = false;
  updatingProductionVolume = false;
  updatingFertilizerMarketPrice = false;
  updatingStockParameters = false;
  factorsCollapsed = true;
  disableButtons = true;
  disableButtonsAdvisor = true;
  isAdvisor = false;
  fertilizerMarket?: string = undefined;
  loadingIconFertilizerMarketPrice = this.fertilizerMarket;
  fertilizerMarketPrices = [PRICE_CONSTANT.HIGH, PRICE_CONSTANT.MEDIUM, PRICE_CONSTANT.LOW];

  constructor(
    private apiService: ApiService,
    private roleService: RoleService,
    private toast: ToastrService
  ) {
    for (let i = 0; i < 52; i++) {
      this.dailyProduction.push({ week: i, value: 0 });
      if (i < 13) {
        this.displayChunks[0].push(i);
      } else if (i < 26) {
        this.displayChunks[1].push(i);
      } else if (i < 39) {
        this.displayChunks[2].push(i);
      } else {
        this.displayChunks[3].push(i);
      }
    }
  }

  async ngOnInit() {
    this.roleService.isAdvisor$.subscribe(isAdvisor => {
      this.isAdvisor = isAdvisor;
    });
    this.apiService.getProducts().subscribe(products => {
      this.productIds = products.map(product => product.id);
      this.productNames = products.map(product => product.name);
      this.productIdSelected$.next(this.productIds[0]);
      this.productIdSelectedLatest = this.productIds[0];
      this.productNameSelected = this.productNames[this.productIds.indexOf(this.productIds[0])];

      this.apiService.getFertilizerMarketPrice(this.productIdSelectedLatest).subscribe(price => {
        this.fertilizerMarket = price.name;
      });
    });
    this.productIdSelected$.subscribe({
      next: productId => {
        if (productId !== null) {
          this.productIdSelectedLatest = productId;
          this.updatingFertilizerMarketPrice = true;
          this.loadingIconFertilizerMarketPrice = undefined;
          forkJoin([
            this.apiService.getPricingParameters(productId),
            this.apiService.getBasePrice(productId, new Date()),
            this.apiService.getStockParameters(productId),
            this.apiService.getStock(productId, new Date()),
            this.apiService.getFertilizerMarketPrice(productId).pipe(catchError(() => [undefined])),
          ]).subscribe({
            next: ([pricingParams, basePrice, stockParams, stock, fertilizerMarketPrice]) => {
              this.loadingPricingParameters = true;
              this.loadingBasePrice = true;
              this.loadingStockParameters = true;
              this.loadingStock = true;
              this.loadingMinMaxPrice = true;
              this.fertilizerMarket = fertilizerMarketPrice?.name;

              // Pricing parameters
              this.pricingParametersLatest = pricingParams;
              this.parameters.forEach(parameter => {
                if (this.pricingParametersLatest) {
                  parameter.weight =
                    this.pricingParametersLatest.weights[parameter.key as keyof IWeights];
                  parameter.factors.values =
                    this.pricingParametersLatest.factors[parameter.key as keyof IFactors];
                  parameter.factors.labels =
                    this.pricingParametersLatest.factors_labels[
                      (parameter.key + '_labels') as keyof IFactorsLabels
                    ];
                }
              });
              this.updateTotalWeight();
              this.loadingPricingParameters = false;
              // Base price
              this.basePrice = basePrice.price;
              this.basePriceNew = this.basePrice;
              this.loadingBasePrice = false;
              // Stock parameters
              this.stockParameters = stockParams;
              if (this.stockParameters?.daily_production) {
                for (let [i, prod] of this.dailyProduction.entries()) {
                  if (i === 0) {
                    this.dailyProductionUpdateAll = this.stockParameters.daily_production[i];
                  }
                  prod.value = this.stockParameters.daily_production[i];
                }
              }
              this.loadingStockParameters = false;
              // Current Stock
              this.currentStock = stock;
              // If no measured stock is available, use the avg predicted
              if (
                this.currentStock.level_measured === undefined ||
                this.currentStock.level_measured === null ||
                this.currentStock.level_measured === 0
              ) {
                this.currentStock.level_measured =
                  Math.round(this.currentStock.level_predicted_avg * 100) / 100;
              }
              // Store the last used value for rollback
              console.log(this.currentStock.level_last_used);
              if (
                this.currentStock.level_last_used === undefined ||
                this.currentStock.level_last_used === null ||
                this.currentStock.level_last_used === 0
              ) {
                console.log('dbg');
                this.currentStock.level_last_used = this.currentStock.level_measured;
              }
              this.loadingStock = false;
              // Compute the min max prices
              this.apiService.computeMinMaxPrice(productId, basePrice.price).subscribe({
                next: minMaxPrice => {
                  this.minMaxPrice = minMaxPrice;
                  this.loadingMinMaxPrice = false;
                },
              });
              this.disableButtons = false;
              if (this.isAdvisor) {
                this.disableButtonsAdvisor = false;
              }

              this.updatingFertilizerMarketPrice = false;
            },
            error: error => {
              console.error(error);
              this.disableButtons = true;
            },
          });
        }
      },
      error: error => {
        console.error(error);
      },
    });
  }

  async onParameterChanges() {
    this.updateTotalWeight();
    this.productIdSelected$.subscribe({
      next: productId => {
        if (productId && this.pricingParametersLatest) {
          this.loadingMinMaxPrice = true;
          const params = this.formatPricingParameters(this.pricingParametersLatest, productId);
          this.apiService.computeMinMaxPrice(productId, this.basePrice, params).subscribe({
            next: minMaxPrice => {
              this.minMaxPrice = minMaxPrice;
              this.loadingMinMaxPrice = false;
            },
          });
        } else {
          throw Error('Product id or latest pricing parameters not available.');
        }
      },
    });
  }

  async selectProduct(index: number) {
    this.productIdSelected$.next(this.productIds[index]);
    this.productNameSelected = this.productNames[this.productIds.indexOf(this.productIds[index])];
  }

  async setBasePrice() {
    if (this.basePriceNew) {
      this.updatingBasePrice = true;
      const dateToday = new Date();
      const datePeriodEnd = new Date(dateToday);
      datePeriodEnd.setDate(dateToday.getDate() + this.period);
      this.apiService
        .setBasePricePeriod(
          this.productIdSelectedLatest!,
          this.basePriceNew,
          dateToday,
          datePeriodEnd
        )
        .subscribe(res => {
          if (res) {
            this.toast.success('Basispreis erfolgreich aktualisiert');
          }
          this.updatingBasePrice = false;
        });
    }
  }

  async onFertilizerMarketChange(item: string) {
    this.loadingIconFertilizerMarketPrice = item;
    this.updatingFertilizerMarketPrice = true;
    let fertilizerMarketPrice = {} as IFertilizerMarketPriceParametersCreate;
    fertilizerMarketPrice.name = item;
    fertilizerMarketPrice.product_id = this.productIdSelectedLatest!;

    this.apiService.setFertilizerMarketPrice(fertilizerMarketPrice).subscribe(res => {
      if (res) {
        this.toast.success('Düngemittelpreise wurden erfolgreich aktualisiert');
        this.fertilizerMarket = item;
      }
      this.updatingFertilizerMarketPrice = false;
    });
  }

  async updateProductionVolumeAll() {
    this.dailyProduction.forEach(prod => {
      prod.value = this.dailyProductionUpdateAll;
    });
  }

  async setProductionVolume() {
    if (this.stockParameters) {
      this.updatingProductionVolume = true;
      let stockParams = {} as IStockParametersCreate;
      stockParams.product_id = this.productIdSelectedLatest!;
      stockParams.daily_production = new Array(12).fill(0);
      for (let [i, prod] of this.dailyProduction.entries()) {
        stockParams.daily_production[i] = prod.value;
      }
      stockParams.threshold_lower = this.stockParameters.threshold_lower;
      stockParams.threshold_upper = this.stockParameters.threshold_upper;
      stockParams.capacity = this.stockParameters.capacity;
      this.apiService.setStockParameters(stockParams).subscribe(res => {
        if (res) {
          this.toast.success('Produktionsmenge erfolgreich aktualisiert');
        }
        this.updatingProductionVolume = false;
      });
    }
  }

  async setStockParams() {
    if (this.stockParameters) {
      this.updatingStockParameters = true;
      let stockParams = {} as IStockParametersCreate;
      stockParams.product_id = this.stockParameters?.product__id;
      stockParams.daily_production = this.stockParameters?.daily_production;
      stockParams.threshold_lower = this.stockParameters?.threshold_lower;
      stockParams.threshold_upper = this.stockParameters?.threshold_upper;
      stockParams.capacity = this.stockParameters?.capacity;
      this.apiService.setStockParameters(stockParams).subscribe(res => {
        if (res) {
          this.toast.success('Lagerparameter erfolgreich aktualisiert');
        }
        this.updatingStockParameters = false;
      });
    }
  }

  async setCurrentStock() {
    if (this.currentStock) {
      this.updatingStock = true;
      const currentStock: IStockUpdateCreate = {
        ...this.currentStock,
        product_id: this.currentStock.product__id,
      };
      this.apiService.setStock(currentStock, new Date()).subscribe(stockResult => {
        this.apiService
          .recalculateStock(this.productIdSelectedLatest!)
          .subscribe(recalculateResult => {
            if (stockResult && recalculateResult) {
              this.toast.success('Lagerbestand erfolgreich aktualisiert');
            }
            this.updatingStock = false;
          });
      });
    }
  }

  private async computePrices(parameters?: IPricingParametersCreate) {
    this.loadingMinMaxPrice = true;
    this.apiService
      .computeMinMaxPrice(this.productIdSelectedLatest!, this.basePrice, parameters)
      .subscribe(minMaxPrice => {
        this.minMaxPrice = minMaxPrice;
        this.loadingMinMaxPrice = false;
      });
  }

  async updatePricingParameters() {
    this.updatingPricingParameters = true;
    let pricing_parameters = this.formatPricingParameters(
      this.pricingParametersLatest!,
      this.productIdSelectedLatest!
    );
    this.apiService.setPricingParameters(pricing_parameters).subscribe(async res => {
      if (res) {
        this.pricingParametersLatest = res;
        this.toast.success('Parameter erfolgreich aktualisiert');
      }
      this.updatingPricingParameters = false;
    });
  }

  private updateTotalWeight() {
    const total = this.parameters.reduce((sum, parameter) => sum + parameter.weight, 0);
    this.totalWeight = total;
    this.parameters.forEach(parameter => (parameter.percentage = (parameter.weight / total) * 100));
    this.totalPercentageCheck = this.parameters.reduce(
      (sum, parameter) => sum + parameter.percentage,
      0
    );
  }

  private formatPricingParameters(
    pricingParams: IPricingParametersRead,
    productId: number
  ): IPricingParametersCreate {
    let pricingParamsCreate = {} as IPricingParametersCreate;
    pricingParamsCreate.product_id = productId;
    pricingParamsCreate.factors_labels = pricingParams.factors_labels;
    pricingParamsCreate.factors = {} as IFactors;
    this.parameters.forEach(parameter => {
      pricingParamsCreate.factors[parameter.key as keyof IFactors] = parameter.factors.values;
    });
    pricingParamsCreate.weights = {} as IWeights;
    this.parameters.forEach(parameter => {
      pricingParamsCreate.weights[parameter.key as keyof IWeights] = parameter.weight;
    });
    return pricingParamsCreate;
  }
}

export { PRICE_CONSTANT };

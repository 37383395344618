<div class="container mt-4 d-flex align-items-center justify-content-center">
  @if (isMobile) {
    <div class="heading">Willkommen</div>
  } @else {
    <div class="heading">Willkommen auf der Handelsplattform Naturdünger</div>
  }
</div>

<div class="container mt-4">
  <div class="row d-flex align-items-center justify-content-center">
    <div class="col-md-5 mt-2">
      @if (plants) {
        <div>
          <select class="form-select" aria-label="Pick location">
            <option selected>Niederuzwil</option>
            <!-- @for (plant of plants; track plant.id) {
              <option [disabled]="plant.id !== 1" [selected]="plant.id === 1">
                {{ plant.name }}
              </option>
            } -->
          </select>
        </div>
      }
    </div>
    <div class="col-md-3 mt-2">
      <button class="btn button-medium" (click)="navigateToPlant()">Naturdünger bestellen</button>
    </div>
  </div>
</div>

<div class="mx-auto container">
  <div class="mt-4 p-3">
    <div
      class="map-container"
      leaflet
      [leafletOptions]="options"
      (leafletMapReady)="onMapReady($event)"
      (leafletClick)="mapClicked($event)"
    >
      <div>
        <button
          class="btn btn-primary bi-cursor-fill location-button"
          (click)="onLocationClick()"
        ></button>
      </div>
      <div>
        <button
          class="btn btn-primary bi-bullseye radius-button"
          (click)="onRadiusClick()"
          [disabled]="!location"
        ></button>
      </div>
      @if (showLegend) {
        <div>
          <div class="legend-liquid">Naturdünger flüssig (20 km)</div>
          <div class="legend-solid">Naturdünger fest (40 km)</div>
        </div>
      }
    </div>
    <div class="d-flex mt-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#CC0000"
        class="bi bi-geo-alt-fill"
        viewBox="0 0 16 16"
      >
        <path
          d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"
        />
      </svg>
      <div class="ml-2">Standorte der Axpo Biomasse AG</div>
    </div>
  </div>
</div>

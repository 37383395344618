<div class="container mt-4">
  <form [formGroup]="filterForm">
    <div class="heading">Bestellhistorie</div>
    <div class="mat-elevation-z0" style="margin-top: 20px">
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0">
        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>
            <div>
              <div class="d-flex align-items-center">
                <label mat-sort-header class="sort-header" sortActionDescription="Sort by number"
                  >{{ isMobile ? 'Nr' : 'Bestellnummer' }}
                </label>
                <i
                  class="bi-search search-icon"
                  (click)="showOrderIdSearch = !showOrderIdSearch"
                ></i>
              </div>
              @if (showOrderIdSearch) {
                <input
                  matInput
                  formControlName="orderNumber"
                  (keyup)="applyFilter()"
                  placeholder="Suchen"
                  class="input-full-width"
                />
              }
            </div>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <!-- User Column -->
        @if (sellerView && isSeller && !isMobile) {
          <ng-container matColumnDef="buyer__first_name">
            <th mat-header-cell *matHeaderCellDef>
              <div>
                <div class="d-flex align-items-center">
                  <label class="sort-header" mat-sort-header sortActionDescription="Sort by name">
                    Käufer
                  </label>
                  <i class="bi-search search-icon" (click)="showBuyerSearch = !showBuyerSearch"></i>
                </div>
                @if (showBuyerSearch) {
                  <input
                    matInput
                    formControlName="buyerName"
                    (keyup)="applyFilter()"
                    placeholder="Suchen"
                    class="input-full-width"
                  />
                }
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.buyer__first_name }} {{ element.buyer__last_name }}
            </td>
          </ng-container>
        }

        <!-- Date Column -->
        @if (!isMobile) {
          <ng-container matColumnDef="order_date_time">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by Bestelldatum"
            >
              <!-- Title text -->
              <mat-form-field class="custom-form-field">
                <mat-label class="sort-header">Bestelldatum</mat-label>
                <mat-date-range-input [rangePicker]="picker" class="hidden-input">
                  <input
                    matStartDate
                    formControlName="orderCreationFromDate"
                    placeholder="Start date"
                  />
                  <input matEndDate formControlName="orderCreationToDate" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker" class="datepicker-toggle">
                </mat-datepicker-toggle>
                <mat-date-range-picker #picker>
                  <mat-date-range-picker-actions>
                    <button
                      class="button-medium"
                      style="margin-top: -48px"
                      matDateRangePickerApply
                      (click)="applyFilter()"
                    >
                      Apply
                    </button>
                  </mat-date-range-picker-actions>
                </mat-date-range-picker>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.order_date_time | date: 'dd.MM.yyy HH:mm' }}
            </td>
          </ng-container>
        }
        <!-- Product Column -->
        <ng-container matColumnDef="product__name">
          <th mat-header-cell *matHeaderCellDef>
            <div>
              <div class="d-flex align-items-center">
                <label mat-sort-header class="sort-header" sortActionDescription="Sort by symbol"
                  >Produkt</label
                >
                <i
                  class="bi-search search-icon"
                  (click)="showProductSearch = !showProductSearch"
                ></i>
              </div>
              @if (showProductSearch) {
                <input
                  matInput
                  formControlName="productName"
                  (keyup)="applyFilter()"
                  placeholder="Suchen"
                  class="input-full-width"
                />
              }
            </div>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.product__name }}</td>
        </ng-container>

        <!-- Amount Column -->
        @if (!isMobile) {
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>
              <div>
                <div class="d-flex align-items-center">
                  <label mat-sort-header class="sort-header" sortActionDescription="Sort by symbol"
                    >Menge&nbsp;m<sup>3</sup></label
                  >
                  <i
                    class="bi-search search-icon"
                    (click)="showAmountSearch = !showAmountSearch"
                  ></i>
                </div>
                @if (showAmountSearch) {
                  <input
                    matInput
                    formControlName="amount"
                    (keyup)="applyFilter()"
                    placeholder="Suchen"
                    class="input-full-width"
                  />
                }
              </div>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
          </ng-container>

          <!-- Price Column -->
          @if (isSeller) {
            <ng-container matColumnDef="sales_price__sales_price">
              <th mat-header-cell *matHeaderCellDef>
                <div>
                  <div class="d-flex align-items-center">
                    <label
                      mat-sort-header
                      class="sort-header"
                      sortActionDescription="Sort by symbol"
                      >Gutschrift&nbsp;CHF</label
                    >
                    <i
                      class="bi-search search-icon"
                      (click)="showPriceSearch = !showPriceSearch"
                    ></i>
                  </div>
                  @if (showPriceSearch) {
                    <input
                      matInput
                      formControlName="salesPrice"
                      (keyup)="applyFilter()"
                      placeholder="Suchen"
                      class="input-full-width"
                    />
                  }
                </div>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.sales_price__sales_price | formatDecimal: 2 : "'" }}
              </td>
            </ng-container>
          }

          <!-- Start and End Pickup Date Column -->
          <ng-container matColumnDef="pickup_start_date">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by Abholzeitraum"
            >
              <!-- Title text -->
              <mat-form-field class="custom-form-field">
                <mat-label class="sort-header">Abholzeitraum</mat-label>
                <mat-date-range-input [rangePicker]="pickupPicker" class="hidden-input">
                  <input matStartDate formControlName="pickupStartDate" placeholder="Start date" />
                  <input matEndDate formControlName="pickupEndDate" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="pickupPicker"
                  class="datepicker-toggle ms-2"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #pickupPicker>
                  <mat-date-range-picker-actions>
                    <button
                      class="button-medium"
                      style="margin-top: -48px"
                      matDateRangePickerApply
                      (click)="applyFilter()"
                    >
                      Apply
                    </button>
                  </mat-date-range-picker-actions>
                </mat-date-range-picker>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.pickup_start_date | date: 'dd.MM.yyy' }} -
              {{ element.pickup_end_date | date: 'dd.MM.yyy' }}
            </td>
          </ng-container>
        }
        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            <div>
              <div class="d-flex align-items-center">
                <label mat-sort-header sortActionDescription="Sort by symbol" class="sort-header"
                  >Status</label
                >
                <i class="bi-search search-icon" (click)="showStatusSearch = !showStatusSearch"></i>
              </div>
              @if (showStatusSearch) {
                <select
                  matInput
                  formControlName="status"
                  (change)="applyFilter()"
                  class="input-full-width"
                >
                  <option value="">Alle</option>
                  <option value="COMPLETE">Abholung vollständig</option>
                  <option value="ORDERED">Bestellt</option>
                  <option value="READY">Abholbereit</option>
                  <option value="DELAYED">Verspätet</option>
                  <option value="INPROGRESS">Abholungsprozess läuft</option>
                  <option value="CANCELLED">Storniert</option>
                </select>
              }
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <span
              class="badge"
              [ngClass]="{
                'bg-secondary': element.status === 'COMPLETE',
                'bg-info': element.status === 'ORDERED',
                'bg-primary': element.status === 'READY',
                'bg-warning': element.status === 'DELAYED',
                'bg-success': element.status === 'INPROGRESS',
                'bg-danger': element.status === 'CANCELLED'
              }"
            >
              {{ translateStatus(element.status) }}
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="navigateToDetail(row.id)"
          class="pointer"
        ></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[10, 20]"
        showFirstLastButtons
        aria-label="Select page of orders"
      >
      </mat-paginator>
    </div>
    @if (!orders) {
      <div>
        <div>
          <div class="spinner-border spinner" role="status"></div>
        </div>
      </div>
    }
  </form>
</div>

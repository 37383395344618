<div class="d-flex justify-content-center">
  <h2 class="m-3">
    {{ title }}
  </h2>
</div>
@if (body) {
  <div class="d-flex justify-content-center">
    <p class="m-3">
      {{ body }}
    </p>
  </div>
}
@if (htmlContent) {
  <div class="m-3" [innerHTML]="htmlContent"></div>
}
<div class="modal-footer">
  <button class="btn button-close" (click)="activeModal.close('Close click')">
    {{ closeButtonText }}
  </button>
  @if (buttonText) {
    <button class="btn button-medium" (click)="buttonAction(); activeModal.close('Close click')">
      {{ buttonText }}
    </button>
  }
</div>

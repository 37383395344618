import { CommonModule, DatePipe } from '@angular/common';
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule, MatSortable } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs';
import { FormatDecimalPipe } from '../../core/pipes/format-decimal.pipe';
import { ApiService } from '../../core/services/api.service';
import { RoleService } from '../../core/services/role.service';
import { StatusService } from '../../core/services/status.service';
import { IOrderOverview, StatusEnum } from '../../shared/types/api_models/order';

@Component({
  selector: 'app-order-overview',
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    CommonModule,
    FormatDecimalPipe,
    MatFormFieldModule,
    MatDatepickerModule,
    DatePipe,
    ReactiveFormsModule,
    MatIconModule,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './order-overview.component.html',
  styleUrl: './order-overview.component.scss',
})
export class OrderOverviewComponent implements OnInit {
  filterForm: FormGroup;

  orders: IOrderOverview[] | undefined;
  isSeller: boolean = false;
  sellerView: boolean = false;
  status: String = '';
  isMobile: boolean = false;
  showOrderIdSearch: boolean = false;
  showBuyerSearch: boolean = false;
  showProductSearch: boolean = false;
  showAmountSearch: boolean = false;
  showPriceSearch: boolean = false;
  showStatusSearch: boolean = false;
  dataSource: MatTableDataSource<IOrderOverview> = new MatTableDataSource<IOrderOverview>([]);

  @Input() set isSellerView(isSellerView: string) {
    this.sellerView = isSellerView === 'true';
  }

  constructor(
    private roleService: RoleService,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private statusService: StatusService,
    private fb: FormBuilder,
  ) {
    this.setDisplay();
    this.filterForm = this.fb.group({
      orderCreationFromDate: [''],
      orderCreationToDate: [''],
      orderNumber: [''],
      buyerName: [''],
      productName: [''],
      amount: [''],
      salesPrice: [''],
      status: [''],
      pickupStartDate: [''],
      pickupEndDate: [''],
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.setDisplay();
  }

  setDisplay() {
    this.isMobile = window.innerWidth < 768;
  }

  get displayedColumns(): string[] {
    let columns = [
      'id',
      'order_date_time',
      'product__name',
      'amount',
      'pickup_start_date',
      'status',
    ];

    if (this.isSeller && !this.isMobile) {
      columns.splice(4, 0, 'sales_price__sales_price');
    }

    if (this.sellerView && this.isSeller && !this.isMobile) {
      columns.splice(1, 0, 'buyer__first_name');
    }

    return this.isMobile ? ['id', 'product__name', 'status'] : columns;
  }

  async ngOnInit() {
    this.roleService.isSeller$.subscribe(isSeller => {
      this.isSeller = isSeller;
      if (isSeller) {
        this.route.url.subscribe(url => {
          url.map(segment => {
            if (segment.path === 'seller') {
              this.sellerView = true;
            }
          });
        });
      }
    });

    try {
      if (this.sellerView) {
        this.apiService.getOrders().subscribe(orders => {
          this.orders = orders;
          this.initTable();
        });
      } else {
        this.apiService.getBuyerOrders().subscribe(orders => {
          this.orders = orders;
          this.initTable();
        });
      }
    } catch {
      this.orders = [];
    }

    this.filterForm.valueChanges
      .pipe(startWith({}), debounceTime(400), distinctUntilChanged())
      .subscribe(val => {
        this.applyFilter();
      });
  }

  translateStatus(status: StatusEnum): string {
    return this.statusService.translate(status);
  }

  async navigateToDetail(orderId: number) {
    const overviewType = this.sellerView ? 'seller' : 'buyer';
    await this.router.navigate(['order-detail', overviewType, orderId]);
  }

  applyFilter() {
    let fromDate = this.filterForm.get('orderCreationFromDate')?.value;
    let toDate = this.filterForm.get('orderCreationToDate')?.value;
    let pickupStartDate = this.filterForm.get('pickupStartDate')?.value;
    let pickupEndDate = this.filterForm.get('pickupEndDate')?.value;
    let orderNumber = this.filterForm.get('orderNumber')?.value;
    let buyerName = this.filterForm.get('buyerName')?.value;
    let productName = this.filterForm.get('productName')?.value;
    let amount = this.filterForm.get('amount')?.value;
    let salesPrice = this.filterForm.get('salesPrice')?.value;
    let status = this.filterForm.get('status')?.value;

    this.dataSource.filterPredicate = (data: any) => {
      let valid = true;

      if (fromDate && toDate) {
        toDate.setHours(23, 59, 59, 999);
        let orderDate = new Date(data.order_date_time);
        valid = valid && orderDate >= new Date(fromDate) && orderDate <= toDate;
      }

      if (pickupStartDate && pickupEndDate) {
        pickupEndDate.setHours(23, 59, 59, 999);
        let rangeStartDate = new Date(data.pickup_start_date);
        let rangeEndDate = new Date(data.pickup_end_date);
        valid =
          valid && rangeStartDate >= new Date(pickupStartDate) && rangeEndDate <= pickupEndDate;
      }

      if (orderNumber) {
        valid = valid && data.id === Number(orderNumber);
      }

      if (buyerName) {
        valid =
          valid &&
          (data.buyer__first_name.toLowerCase().includes(buyerName.toLowerCase()) ||
            data.buyer__last_name.toLowerCase().includes(buyerName.toLowerCase()));
      }

      if (productName) {
        valid = valid && data.product__name.toLowerCase().includes(productName.toLowerCase());
      }

      if (amount) {
        valid = valid && data.amount === Number(amount);
      }

      if (salesPrice) {
        valid = valid && data.sales_price__sales_price === Number(salesPrice);
      }

      if (status) {
        valid = valid && data.status.toLowerCase().includes(status.toLowerCase());
      }

      return valid;
    };

    // Trigger the filter to update the table
    this.dataSource.filter = '' + Math.random();
  }

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  initTable() {
    this.dataSource = new MatTableDataSource(this.orders);
    this.paginator._intl.itemsPerPageLabel = 'Objekte pro Seite';
    this.dataSource.paginator = this.paginator;
    this.sort.sort({ id: 'order_date_time', start: 'desc' } as MatSortable);
    this.dataSource.sort = this.sort;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../core/services/api.service';
import { IOrderOverview, StatusEnum } from '../../shared/types/api_models/order';
import { ActivatedRoute, Router } from '@angular/router';
import { IPickupRead } from '../../shared/types/api_models/pickup';
import { saveAs } from 'file-saver';
import { RoleService } from '../../core/services/role.service';
import { forkJoin } from 'rxjs';
import { StatusService } from '../../core/services/status.service';
import { AmountService } from '../../core/services/amount.service';
import { ModalService } from '../../core/services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { FormatDecimalPipe } from '../../core/pipes/format-decimal.pipe';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-order-detail',
  standalone: true,
  imports: [CommonModule, FormatDecimalPipe, NgbPopover],
  templateUrl: './order-detail.component.html',
  styleUrl: './order-detail.component.scss',
})
export class OrderDetailComponent implements OnInit {
  @Input({ required: true }) orderId!: number;
  @Input() overviewType: string = 'buyer';
  isSeller: boolean = false;
  order: IOrderOverview | undefined;
  pickups: IPickupRead[] | undefined;
  loading: boolean = true;
  pickupSum: number = 0;
  pickupPercentage: number = 0;
  amountLeft: number | undefined;

  constructor(
    private apiService: ApiService,
    private roleService: RoleService,
    private router: Router,
    private route: ActivatedRoute,
    private statusService: StatusService,
    private amountService: AmountService,
    private toastService: ToastrService,
    private modalService: ModalService,
  ) {}

  async ngOnInit() {
    forkJoin([
      this.roleService.isSeller$,
      this.apiService.getOrder(this.orderId),
      this.apiService.getPickups(this.orderId),
    ]).subscribe(([isSeller, order, pickups]: [boolean, IOrderOverview, IPickupRead[]]) => {
      this.isSeller = isSeller;
      this.order = order;
      this.pickups = pickups;
      this.loading = false;
      this.pickupSum = this.amountService.calculatePickupSum(this.pickups);
      this.pickupPercentage = (this.pickupSum / this.order.amount) * 100;
      this.amountLeft = this.order.amount - this.pickupSum;
    });
  }

  async navigateToOverview() {
    await this.router.navigate([`order-overview/${this.overviewType}`]);
  }

  async navigateToPickUp() {
    await this.router.navigate(['pick-up'], { relativeTo: this.route });
  }

  async downloadCsv() {
    this.apiService.downloadCsv(this.orderId).subscribe(response => {
      const filename = `order_${this.orderId}_export.csv`;
      saveAs(response, filename);
    });
  }

  cancelOrder() {
    this.apiService.cancelOrder(this.orderId).subscribe((order: IOrderOverview) => {
      this.order = order;
      this.toastService.success('Bestellung storniert');
      this.apiService.recalculateStock(this.order.product__id).subscribe(() => {
        this.toastService.success('Lagerbestand aktualisiert');
      });
    });
  }

  openCancelModal() {
    const options = {
      title: 'Bestellung stornieren',
      body: 'Wollen Sie die Bestellung wirklich stornieren?',
      buttonText: 'Ja, stornieren',
      buttonAction: () => this.cancelOrder(),
      closeButtonText: 'Abbrechen',
    };
    this.modalService.openModal(options);
  }

  translateStatus(status: StatusEnum): string {
    return this.statusService.translate(status);
  }

  // getKeys(obj: any): string[] {
  //   return Object.keys(obj);
  // }
  //
  // getValue(obj: any, key: string){
  //   return obj[key as keyof IOrder]
  // }
  protected readonly StatusEnum = StatusEnum;
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AmountService {
  calculatePickupSum(pickups: any[]): number {
    if (!pickups || pickups.length === 0) {
        return 0;
      }
    let totalPickupAmount = pickups.reduce((sum, pickup) => sum + pickup.amount, 0);
    return totalPickupAmount;
  }
}
<form class="row row-cols-lg-auto">
  <div class="col-xl" style="margin-bottom: 5px">
    <div class="dp-hidden position-absolute">
      <div class="input-group">
        <input
          name="datepicker"
          class="form-control"
          ngbDatepicker
          #datepicker="ngbDatepicker"
          [autoClose]="'outside'"
          [placement]="placement"
          (dateSelect)="onDateSelectionCalendar($event)"
          [displayMonths]="displayMonths"
          [dayTemplate]="t"
          outsideDays="hidden"
          [startDate]="fromDate!"
          [minDate]="minSelectableDate ? minSelectableDate : { year: 1900, month: 1, day: 1 }"
          [maxDate]="maxSelectableDate ? calculateMaxDate() : { year: 2100, month: 1, day: 1 }"
          tabindex="-1"
        />
        <ng-template #t let-date let-focused="focused">
          <span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            [class.disabled]="isDisabled(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
          >
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>
    <div class="input-group">
      <input
        #dpFromDate
        class="form-control"
        placeholder="TT.MM.JJJJ"
        name="dpFromDate"
        [(ngModel)]="fromDateForm"
        (blur)="validateAndProcessInput(dpFromDate.value, true)"
      />
      <button
        class="btn btn-outline-secondary bi bi-calendar3"
        (click)="datepicker.toggle()"
        type="button"
      ></button>
    </div>
  </div>
  <div class="col-xl">
    <div class="input-group">
      <input
        #dpToDate
        class="form-control"
        placeholder="TT.MM.JJJJ"
        name="dpToDate"
        [(ngModel)]="toDateForm"
        (blur)="validateAndProcessInput(dpToDate.value, false)"
      />
      <button
        class="btn btn-outline-secondary bi bi-calendar3"
        (click)="datepicker.toggle()"
        type="button"
      ></button>
    </div>
  </div>
</form>

import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../core/services/api.service';
import { IUser } from '../../shared/types/api_models/user';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../../core/services/modal.service';
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit {
  user: IUser = {} as IUser;
  editing = false;
  saving = false;

  profileForm = this.fb.nonNullable.group({
    external_id: <string | null>null,
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: '',
    phone: '',
  });

  constructor(
    private userService: UserService,
    private modalService: ModalService,
    private fb: FormBuilder,
  ) {}

  async ngOnInit() {
    this.userService.user$.subscribe(user => {
      this.user = user;
      this.profileForm.patchValue(this.user);
    });
  }

  toggleEdit() {
    this.editing = true;
  }

  cancelEdit() {
    this.editing = false;
    this.profileForm.reset(this.user);
  }

  async updateUser() {
    // TODO check how user can be updated with keyclaok
    //   this.saving = true;
    //   let updatedUser = await this.apiService.requestAsPromise(
    //     'PUT',
    //     `users/${this.user.id}`, { body: this.profileForm.value }
    //   );
    //   this.saving = false;
    //   if (updatedUser) {
    //     this.openConfirmationModal();
    //     this.editing = false;
    //   } else {
    //     this.toast.error('Profiländerung fehlgeschlagen');
    //   }
  }

  openConfirmationModal() {
    const options = {
      body: 'Profil erfolgreich geändert.',
      title: 'Profiländerung',
    };
    this.modalService.openModal(options);
  }
}

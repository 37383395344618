import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../core/services/api.service';
import {
  IOrderOverview,
  IOrderUpdateStatus,
  StatusEnum,
} from '../../shared/types/api_models/order';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ModalService } from '../../core/services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { IPickupCreate, IPickupRead } from '../../shared/types/api_models/pickup';
import { NgbCalendar, NgbDate, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { AmountService } from '../../core/services/amount.service';

@Component({
  selector: 'app-pick-up',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgbPopover],
  templateUrl: './pick-up.component.html',
  styleUrl: './pick-up.component.scss',
})
export class PickUpComponent implements OnChanges {
  @Input({ required: true }) orderId!: number;
  order: IOrderOverview | undefined;
  pickups: IPickupRead[] | undefined;
  loading: boolean = true;
  saving: boolean = false;
  timeInvalid: boolean = false;
  dateToday: NgbDate | null = null;
  dateTodayString: string | undefined = undefined;
  pickupSum: number = 0;
  amountLeft: number | undefined;
  orderReadyToComplete: boolean = false;
  orderDefComplete: boolean = false;
  maxAmount: number = 0;
  minAmount: number = 0;

  pickupForm = this.fb.nonNullable.group({
    date: ['', Validators.required],
    amount: [0, Validators.min(1)],
  });

  constructor(
    private apiService: ApiService,
    private toast: ToastrService,
    private modalService: ModalService,
    private fb: FormBuilder,
    private calendar: NgbCalendar,
    private route: ActivatedRoute,
    private router: Router,
    private amountService: AmountService,
  ) {
    this.dateToday = this.calendar.getToday();
    this.dateTodayString = `${this.dateToday.year}-${this.dateToday.month
      .toString()
      .padStart(2, '0')}-${this.dateToday.day.toString().padStart(2, '0')}`;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['orderId'] || changes['pickups']) {
      forkJoin([
        this.apiService.getOrder(this.orderId),
        this.apiService.getPickups(this.orderId),
      ]).subscribe(([order, pickups]: [IOrderOverview, IPickupRead[]]) => {
        this.order = order;
        this.pickups = pickups;
        this.pickupForm.patchValue({ date: this.dateTodayString });
        this.compareDate();
        this.loading = false;
        this.pickupSum = this.amountService.calculatePickupSum(this.pickups);
        this.amountLeft = this.order.amount - this.pickupSum;
        this.maxAmount = Math.floor(this.order?.amount * 1.1);
        this.minAmount = Math.floor(this.order?.amount * 0.9);
        this.checkAmountRestrictions();
        this.checkReadyToComplete();
      });
    }
  }

  async onBack() {
    window.history.back();
  }

  async updatePickups() {
    if (this.pickupForm.valid) {
      this.saving = true;
      let pickup = {} as IPickupCreate;
      pickup.order_id = this.orderId;
      pickup.date = this.pickupForm.value.date!;
      pickup.amount = this.pickupForm.value.amount!;
      this.apiService.addPickup(pickup).subscribe(updatedPickups => {
        if (updatedPickups) {
          this.saving = false;
          this.apiService.getPickups(this.orderId).subscribe(pickups => {
            this.pickupForm.reset({ date: this.dateTodayString, amount: 0 });
            this.pickups = pickups;
            this.pickupSum = this.amountService.calculatePickupSum(this.pickups);
            this.amountLeft = (this.order?.amount || 0) - this.pickupSum;
            this.checkReadyToComplete();
            if (!this.orderReadyToComplete) {
              this.openConfirmationModal();
            }
            if (
              this.pickups &&
              this.pickups.length === 1 &&
              this.order &&
              this.pickupSum < this.order.amount
            ) {
              this.updateOrderStatus(StatusEnum.INPROGRESS);
            }
            if (this.order && this.pickupSum >= this.order.amount) {
              this.updateOrderStatus(StatusEnum.COMPLETE);
            }
          });
        } else {
          this.toast.error('Abholungserfassung fehlgeschlagen');
        }
      });
    } else {
      this.toast.error('Bitte füllen Sie alle Felder aus');
    }
  }

  updateOrderStatus(status: StatusEnum) {
    if (this.order) {
      this.order.status = status;
      const orderUpdate = {} as IOrderUpdateStatus;
      orderUpdate.id = this.orderId;
      orderUpdate.status = this.order.status;
      this.apiService.updateOrderStatus(orderUpdate).subscribe();
    }
  }

  openConfirmationFinishOrderModal() {
    const options = {
      body: 'Nach Abschliessen können Sie keine weiteren Abholungen mehr tätigen, sind Sie sicher?',
      title: '',
      buttonText: 'Ja',
      closeButtonText: 'Abbrechen',
      buttonAction: () => this.saveAndCompleteOrder(),
    };
    this.modalService.openModal(options);
  }

  async saveAndCompleteOrder() {
    if (this.order && this.orderReadyToComplete) {
      if (this.pickupForm.valid && !this.timeInvalid) {
        this.saving = true;
        let pickup = {} as IPickupCreate;
        pickup.order_id = this.orderId;
        pickup.date = this.pickupForm.value.date!;
        pickup.amount = this.pickupForm.value.amount!;
        this.apiService.addPickup(pickup).subscribe(updatedPickups => {
          if (updatedPickups) {
            this.completeOrder();
          } else {
            this.toast.error('Abholungserfassung fehlgeschlagen');
          }
        });
      } else {
        this.completeOrder();
      }
    }
  }

  async completeOrder() {
    if (this.order && this.orderReadyToComplete) {
      this.order.status = StatusEnum.COMPLETE;
      const orderUpdate = {} as IOrderUpdateStatus;
      orderUpdate.id = this.orderId;
      orderUpdate.status = this.order.status;
      this.apiService.updateOrderStatus(orderUpdate).subscribe(response => {
        this.openCompleteModal();
      });
    }
  }

  openConfirmationModal() {
    const options = {
      body: 'Abholung erfolgreich erfasst.',
      title: 'Abholung',
      buttonText: 'Zur Bestellübersicht',
      buttonAction: () => this.navigateToDetail(),
    };
    this.modalService.openModal(options);
  }

  async openCompleteModal() {
    const options = {
      body: 'Der Abholprozess für die Bestellung wurde erfolgreich abgeschlossen.',
      title: 'Abholprozess abgeschlossen',
    };
    this.modalService.openModal(options);
    await this.navigateToDetail();
  }

  compareDate() {
    let pickupDate = this.pickupForm.value.date;
    let endDate = this.order?.pickup_end_date;
    let startDate = this.order?.pickup_start_date;
    if (pickupDate != null && endDate != null && startDate != null) {
      if (pickupDate > endDate || pickupDate < startDate) {
        this.timeInvalid = true;
      } else {
        this.timeInvalid = false;
      }
    }
  }

  checkAmountRestrictions() {
    const amount = this.pickupForm.value.amount || 0;
    if (amount < 0) {
      this.pickupForm.get('amount')?.setValue(0);
    }

    if (!this.order) return;

    if (amount + this.pickupSum > this.maxAmount - 1) {
      this.pickupForm.get('amount')?.setValue(this.maxAmount - this.pickupSum - 1);
      this.toast.error('Maximale Abholmenge erreicht.');
    }
  }

  checkReadyToComplete() {
    this.orderReadyToComplete = this.pickupSum !== 0;
  }

  async navigateToDetail() {
    await this.router.navigate(['..'], { relativeTo: this.route });
  }
}

@if (!isWelcomePage()) {
  <div ngbDropdown class="location-dropdown">
    @if (plants) {
      <select class="form-select" aria-label="Pick location">
        <option selected>Axpo Biomasse AG Niederuzwil</option>
        <!-- @for (plant of plants; track plant.id) {
          <option
            [selected]="plant.id === 1"
            [disabled]="plant.id !== 1"
            (click)="navigateToPlant(plant.id)"
          >
            {{ plant.name }}
          </option>
        } -->
      </select>
    }
  </div>
}

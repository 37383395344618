<div class="container mt-4">
  <div class="heading">Impressum</div>

  <div class="container fixed-width">
    <div class="my-4">
      Die Handelsplattform Naturdünger ist eine Marke der Axpo Biomasse AG. Die Axpo Biomasse AG
      betreibt diese Plattform und ist für deren Inhalt verantwortlich. Es gelten die Allgemeinen
      Geschäftbedingungen (AGBs).
    </div>

    <div class="my-4">
      <strong>Adresse</strong><br />
      Axpo Biomasse AG<br />
      Parkstrasse 23<br />
      CH-5401 Baden
    </div>

    <div class="my-2">
      <strong>Kontakt:</strong> <br />
      Tel.: +41 56 200 31 11 <br />
      <a class="active-link" href="mailto:info@axpo.com">E-Mail</a>
    </div>

    <h5 class="mt-4">Weitere Informationen</h5>
    <ul>
      <li>
        <a class="active-link" href="https://www.axpo.com/group/de/datenschutzerklaerung.html"
          >Datenschutzerklärung</a
        >
      </li>
      <li><a class="active-link" href="https://www.axpo.com/group/de.html">Axpo</a></li>
      <li>
        <a
          class="active-link"
          href="https://www.axpo.com/group/de/news-und-stories/medienkontakt.html"
          >Medienkontakt</a
        >
      </li>
    </ul>
  </div>
</div>

export interface IOrderOverview {
  id: number;
  buyer__first_name: string;
  buyer__last_name: string;
  plant__id: number;
  plant__name: string;
  product__id: number;
  product__name: string;
  amount: number;
  order_date_time: string;
  pickup_start_date: string;
  pickup_end_date: string;
  delivery_option: DeliveryEnum;
  status: StatusEnum;
  sales_price__sales_price: number;
}

export interface IOrderCreate {
  buyer_external_id: string;
  plant_id: number;
  product_id: number;
  sales_price_id: number;
  amount: number;
  pickup_start_date: string;
  pickup_end_date: string;
  delivery_option: DeliveryEnum;
  status: StatusEnum;
}

export interface IOrderUpdateStatus {
  id: number;
  status: StatusEnum;
}

export enum StatusEnum {
  ORDERED = 'ORDERED',
  READY = 'READY',
  INPROGRESS = 'INPROGRESS',
  DELAYED = 'DELAYED',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
}

export enum DeliveryEnum {
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY',
}

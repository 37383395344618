import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs';
import { RoleService } from '../services/role.service';

export const sellerGuard: CanActivateFn = (
  _next: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
) => {
  const roleService = inject(RoleService);
  const router = inject(Router);

  return roleService.isSeller$.pipe(
    map(isSeller => {
      if (!isSeller) {
        router.navigate(['/unauthorized']);
      }
      return isSeller;
    }),
  );
};

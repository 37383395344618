import {
  Component,
  Output,
  EventEmitter,
  AfterViewInit,
  Input,
  Injectable,
  HostListener,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-datepicker-range',
  standalone: true,
  imports: [NgbDatepickerModule, FormsModule, CommonModule],
  templateUrl: './datepicker-range.component.html',
  styleUrl: './datepicker-range.component.scss',
})
export class DatepickerRangeComponent implements OnInit, AfterViewInit {
  @Output() fromDateEvent = new EventEmitter<NgbDate | null>();
  @Output() toDateEvent = new EventEmitter<NgbDate | null>();
  @Input() minSelectableDate: NgbDate | undefined;
  @Input() maxSelectableDate: NgbDate | undefined;
  @Input() maxSelectablePeriod: number | undefined;

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  fromDateForm: string | null;
  toDateForm: string | null;
  displayMonths: number = 2;
  placement = 'bottom-start';

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    this.fromDateForm = this.formatter.format(this.fromDate);
    this.toDateForm = this.formatter.format(this.toDate);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.setDisplay();
  }

  ngOnInit() {
    this.setDisplay();
  }

  setDisplay(): void {
    this.displayMonths = window.innerWidth < 768 ? 1 : 2;
    this.placement = window.innerWidth < 768 ? 'bottom' : 'bottom-start';
  }

  ngAfterViewInit() {
    // Emit events such that default selection is passed to parents
    this.fromDateEvent.emit(this.fromDate);
    this.toDateEvent.emit(this.toDate);
  }

  onDateSelectionCalendar(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      (date.after(this.fromDate) || date.equals(this.fromDate))
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.fromDateEvent.emit(this.fromDate);
    this.toDateEvent.emit(this.toDate);
    this.fromDateForm = this.formatter.format(this.fromDate);
    this.toDateForm = this.formatter.format(this.toDate);
  }

  onDateSelectionInput(date: NgbDate, isFromInput: boolean) {
    if (isFromInput) {
      this.fromDate = date;
      if (this.toDate && date.after(this.toDate)) {
        this.toDate = date;
      }
    }
    if (!isFromInput) {
      this.toDate = date;
      if (this.fromDate && date.before(this.fromDate)) {
        this.fromDate = date;
      }
    }
    if (this.fromDate && this.toDate) {
      this.fromDateEvent.emit(this.fromDate);
      this.toDateEvent.emit(this.toDate);
      this.fromDateForm = this.formatter.format(this.fromDate);
      this.toDateForm = this.formatter.format(this.toDate);
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  isDisabled = (date: NgbDate) => {
    return date.before(this.minSelectableDate) || date.after(this.calculateMaxDate());
  };

  calculateMaxDate(): NgbDate {
    if (!this.maxSelectablePeriod || !this.fromDate || this.toDate) {
      return this.maxSelectableDate!;
    } else {
      const maxSelectablePeriodDate = this.calendar.getNext(
        this.fromDate,
        'd',
        this.maxSelectablePeriod,
      );
      if (maxSelectablePeriodDate.after(this.maxSelectableDate)) {
        return this.maxSelectableDate!;
      }
      return this.calendar.getNext(this.fromDate, 'd', this.maxSelectablePeriod);
    }
  }

  validateAndProcessInput(input: string, isFromInput: boolean): void {
    const dateParsed = NgbDate.from(this.formatter.parse(input));
    if (
      dateParsed &&
      this.calendar.isValid(dateParsed) &&
      dateParsed.after(this.calendar.getToday())
    ) {
      this.onDateSelectionInput(dateParsed, isFromInput);
    }
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '.';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

<div class="container-sm mt-4">
  <div class="heading">Details der Bestellung</div>
  <div style="margin-top: 20px">
    <div class="button-container">
      <button class="btn button-medium-inverted" type="button" (click)="navigateToOverview()">
        Zurück zur Übersicht
      </button>
      <div
        [ngbPopover]="
          order?.status === StatusEnum.COMPLETE ||
          order?.status === StatusEnum.ORDERED ||
          order?.status === StatusEnum.CANCELLED
            ? 'Sobald das Startdatum der Abholung erreicht ist, kann die Abholung hier erfasst werden.'
            : null
        "
        triggers="mouseenter:mouseleave"
      >
        <button
          class="btn button-medium"
          type="button"
          [disabled]="
            order?.status === StatusEnum.COMPLETE ||
            order?.status === StatusEnum.ORDERED ||
            order?.status === StatusEnum.CANCELLED
          "
          (click)="navigateToPickUp()"
        >
          Abholung erfassen
        </button>
      </div>
      @if (isSeller) {
        <button class="btn button-medium" type="button" (click)="downloadCsv()">
          CSV herunterladen
        </button>
      }
      @if (isSeller && this.pickupSum === 0) {
        <button
          class="btn button-medium-danger"
          [disabled]="order?.status === StatusEnum.CANCELLED"
          type="button"
          (click)="openCancelModal()"
        >
          Stornieren
        </button>
      }
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
      <table class="table mt-3">
        @if (order) {
          <tbody>
            <tr>
              <td>Bestellnummer</td>
              <td>{{ order.id }}</td>
            </tr>
            <tr>
              <td>Käufer</td>
              <td>{{ order.buyer__first_name }} {{ order.buyer__last_name }}</td>
            </tr>
            <tr>
              <td>Anlage</td>
              <td>{{ order.plant__name }}</td>
            </tr>
            <tr>
              <td>Produkt</td>
              <td>{{ order.product__name }}</td>
            </tr>
            <tr>
              <td>Menge m<sup>3</sup></td>
              <td>{{ order.amount }}</td>
            </tr>
            @if (isSeller) {
              <tr>
                <td>Verkaufspreis CHF</td>
                <td>{{ order.sales_price__sales_price | formatDecimal: 2 : "'" }}</td>
              </tr>
            }
            <tr>
              <td>Bestelldatum</td>
              <td>{{ order.order_date_time | date: 'dd.MM.yyyy HH:mm' }}</td>
            </tr>
            <tr>
              <td>Abholzeitraum</td>
              <td>
                {{ order.pickup_start_date | date: 'dd.MM.yyyy' }} -
                {{ order.pickup_end_date | date: 'dd.MM.yyyy' }}
              </td>
            </tr>
            <tr>
              <td>Abholart</td>
              <td>
                {{ order.delivery_option === 'DELIVERY' ? 'Lieferung' : 'Selbstabholung' }}
              </td>
            </tr>
            <tr>
              <td>Status</td>
              <td>
                <span
                  class="badge"
                  [ngClass]="{
                    'bg-secondary': order.status === 'COMPLETE',
                    'bg-info': order.status === 'ORDERED',
                    'bg-primary': order.status === 'READY',
                    'bg-warning': order.status === 'DELAYED',
                    'bg-success': order.status === 'INPROGRESS',
                    'bg-danger': order.status === 'CANCELLED'
                  }"
                >
                  {{ translateStatus(order.status) }}
                </span>
              </td>
            </tr>
            <tr>
              <td>Bereits abgeholte Menge m<sup>3</sup></td>
              <td>
                <div class="d-flex align-items-center">
                  <span>
                    {{ pickupSum }}
                  </span>
                  <div class="progress pg-container col-md-5 ms-3">
                    <div
                      class="progress-bar pg-bar"
                      role="progressbar"
                      style="width: {{ pickupPercentage }}%;"
                    >
                      {{ pickupPercentage | formatDecimal: 0 }} %
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Abholungen</td>

              <td>
                @for (pickup of pickups; track pickup.id) {
                  <div>
                    {{ pickup.date | date: 'dd.MM.yyyy' }} - Menge: {{ pickup.amount }} -
                    <i>Erfasst am: {{ pickup.datetime_created | date: 'dd-MM-yyyy HH:mm' }}</i>
                  </div>
                } @empty {
                  <div>Es wurde noch nichts abgeholt.</div>
                }
              </td>
            </tr>

            <!-- TODO this could be used to display all parameters if clicking on the sales price -->
            <!--     @for(key of getKeys(order); track key){-->
            <!--       <tr>-->
            <!--         <td>{{ key}}</td>-->
            <!--         <td> {{ getValue(order, key) }}</td>-->
            <!--       </tr>-->
            <!--     }-->
          </tbody>
        }
      </table>
    </div>
  </div>
  @if (loading) {
    <div class="spinner-border spinner" role="status"></div>
  }
</div>

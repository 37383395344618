import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../shared/components/modal/modal.component';

export interface IButtonOptions {
  title: string;
  body: string;
  htmlContent?: string;
  id?: number;
  buttonText?: string;
  buttonAction?: Function;
  closeButtonText?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private modalService: NgbModal) {}

  openModal(options: IButtonOptions) {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.title = options.title;
    modalRef.componentInstance.body = options.body;
    if (options.htmlContent) {
      modalRef.componentInstance.htmlContent = options.htmlContent;
    }
    if (options.id) {
      modalRef.componentInstance.id = options.id;
    }
    if (options.buttonText) {
      modalRef.componentInstance.buttonText = options.buttonText;
    }
    if (options.buttonAction) {
      modalRef.componentInstance.buttonAction = options.buttonAction;
    }
    if (options.closeButtonText) {
      modalRef.componentInstance.closeButtonText = options.closeButtonText;
    }
  }
}

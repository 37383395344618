<div class="container mt-5">
  <div class="heading">{{ plant?.name }}</div>
  <div class="row mt-4">
    <div class="col-lg-3">
      @if (plant?.opening_hours) {
        <div class="title my-4">Öffnungszeiten</div>
        @for (openingHour of plant?.opening_hours?.element; track openingHour) {
          <div class="text-semibold mt-4">{{ openingHour.title }}</div>
          <div>
            @if (openingHoursBody) {
              @for (body of openingHoursBody; track body) {
                <div>{{ body }}</div>
              }
            }
          </div>
        }
      }
    </div>

    <div class="col-lg-3">
      <div class="title my-4">Adresse</div>
      <div class="text-semibold">{{ plant?.name }}</div>
      <div>
        <!-- {{ plant?.phone }}<br /> -->
        @if (plant?.address) {
          @for (address of addresses; track address) {
            <div>{{ address }}</div>
          }
        }
      </div>
      <div class="title my-4">Kontakt</div>
      @if (contacts) {
        <div class="text-semibold mt-4">{{ contacts[0] }}</div>
        <div>
          {{ contacts[1] }} <br />
          {{ contacts[2] }} <br />
          {{ contacts[3] }}
        </div>
      }
    </div>

    <div class="col-lg-5 mt-4 mb-4 d-flex justify-content-center">
      <img src="/assets/standort_jona_logo.jpg" class="img-fluid" alt="image" />
    </div>
  </div>
</div>

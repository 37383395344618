<div class="container mt-4 text-align-center">
  @if (loading) {
    <div class="spinner-border spinner" role="status"></div>
  }
  <div class="row">
    <div class="col-lg-12">
      <div class="d-flex justify-content-between">
        @if (!loading) {
          <span ngbDropdown class="dropdown">
            <button ngbDropdownToggle class="btn button-medium">
              {{ productNameSelected ? productNameSelected : 'Produkt wählen' }}
            </button>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
              @for (name of productNames; track $index) {
                <div ngbDropdownItem class="dropdown-item" (click)="selectProduct($index)">
                  {{ name }}
                </div>
              }
            </div>
          </span>
          <span ngbDropdown class="dropdown d-flex justify-content-end align-items-center">
            <span class="text-semibold me-2">Zeitraum</span>
            <button ngbDropdownToggle class="btn button-medium-inverted">
              {{ periodSelected }} Tage
            </button>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
              @for (name of periodsAvailable; track $index) {
                <div ngbDropdownItem class="dropdown-item" (click)="selectPeriod($index)">
                  {{ name }} Tage
                </div>
              }
            </div>
          </span>
        }
      </div>
      <canvas id="stockChart"></canvas>
    </div>
  </div>
</div>

<div class="container mt-4 text-align-center">
  <div *ngIf="loading" class="spinner-border spinner" role="status"></div>
  <div class="row">
    <div class="col-md-6">
      <canvas id="orderChart"></canvas>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, map } from 'rxjs';

interface UserProfile {
  sub: string;
  email: string;
  roles: string[];
}

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private oidcSecurityService: OidcSecurityService) {}

  get isSeller$(): Observable<boolean> {
    return this.oidcSecurityService.getUserData().pipe(
      map(data => {
        const userData = data as unknown as UserProfile;
        return this.hasSellerRoles(userData.roles);
      }),
    );
  }

  get isAdvisor$(): Observable<boolean> {
    return this.oidcSecurityService.getUserData().pipe(
      map(data => {
        const userData = data as unknown as UserProfile;
        return this.hasAdvisorRole(userData.roles);
      }),
    );
  }

  get isOperator$(): Observable<boolean> {
    return this.oidcSecurityService.getUserData().pipe(
      map(data => {
        const userData = data as unknown as UserProfile;
        return this.hasOperatorRole(userData.roles);
      }),
    );
  }

  private hasSellerRoles(roles: string[]): boolean {
    return roles.includes('advisor') || roles.includes('operator');
  }

  private hasAdvisorRole(roles: string[]): boolean {
    return roles.includes('advisor');
  }

  private hasOperatorRole(roles: string[]): boolean {
    return roles.includes('operator');
  }
}

<div class="container mt-4">
  <div class="heading">Profil</div>
  <div class="profile-container">
    <form [formGroup]="profileForm" (ngSubmit)="updateUser()">
      <div class="input-form-element">
        <div class="input-textbox text-large">Vorname</div>
        <div class="input-small">
          @if (!editing) {
            <div>{{ profileForm.value.first_name }}</div>
          } @else {
            <input type="text" formControlName="first_name" class="form-control" />
            @if (profileForm.controls.first_name.invalid) {
              <small class="form-text">Bitte Vornamen eingeben.</small>
            }
          }
        </div>
      </div>

      <div class="input-form-element">
        <div class="input-textbox text-large">Nachname</div>
        <div class="input-small">
          @if (!editing) {
            <div>{{ profileForm.value.last_name }}</div>
          } @else {
            <input type="text" formControlName="last_name" class="form-control" />
            @if (profileForm.controls.last_name.invalid) {
              <small class="form-text">Bitte Nachnamen eingeben.</small>
            }
          }
        </div>
      </div>

      <div class="input-form-element">
        <div class="input-textbox text-large">E-Mail</div>
        @if (!editing) {
          <div>{{ profileForm.value.email }}</div>
        } @else {
          <input type="text" formControlName="email" class="form-control" />
        }
      </div>

      <div class="input-form-element">
        <div class="input-textbox text-large">Telefonnummer</div>
        @if (!editing) {
          <div>{{ profileForm.value.phone }}</div>
        } @else {
          <input type="text" formControlName="phone_number" class="form-control" />
        }
      </div>

      <!--      TODO: Enable once requested and possible with keycloak-->
      <!--      <div class="button-container">-->
      <!--        @if (editing) {-->
      <!--          <button class="btn button-medium" type="submit">-->
      <!--            Änderungen speichern-->
      <!--            @if (saving) {-->
      <!--              <div>-->
      <!--                <div class="spinner-border spinner-border-sm spinner-button" role="status"></div>-->
      <!--              </div>-->
      <!--            }-->
      <!--          </button>-->
      <!--          <button class="btn button-close" type="button" (click)="cancelEdit()">Abbrechen</button>-->
      <!--        } @else {-->
      <!--          <button class="btn button-medium" type="button" (click)="toggleEdit()" [disabled]="true">-->
      <!--            Profil bearbeiten-->
      <!--          </button>-->
      <!--        }-->
      <!--      </div>-->
    </form>
  </div>
</div>

<div class="container mt-4">
  <div class="heading">Kundenübersicht</div>
  <div class="table-responsive">
    <table class="table table-hover" style="margin-top: 20px">
      <thead>
        <tr>
          <th scope="col">Kundennummer</th>
          <th scope="col">Vorname</th>
          <th scope="col">Nachname</th>
          <th scope="col">Telefonnummer</th>
          <th scope="col">E-Mail</th>
          <th scope="col">Organization</th>
          <th scope="col">Rolle</th>
        </tr>
      </thead>
      @if (buyers) {
        <tbody>
          @for (buyer of buyers; track buyer.id) {
            <tr>
              <td>{{ buyer.id }}</td>
              <td>{{ buyer.first_name }}</td>
              <td>{{ buyer.last_name }}</td>
              <td>{{ buyer.phone }}</td>
              <td>{{ buyer.email }}</td>
              <td>{{ buyer.organization__name }}</td>
              <!--          TODO add role and organization-->
              <!--          <td>-->
              <!--            {{-->
              <!--              buyer.role === UserRoleEnum.BUYER_END_CUSTOMER-->
              <!--                ? 'Endkunde'-->
              <!--                : buyer.role === UserRoleEnum.BUYER_CONTRACTOR-->
              <!--                  ? 'Lohnunternehmer'-->
              <!--                  : buyer.role-->
              <!--            }}-->
              <!--          </td>-->
            </tr>
          } @empty {
            <p>Keine Kunden gefunden.</p>
          }
        </tbody>
      }
    </table>
  </div>

  @if (!buyers) {
    <div>
      <div>
        <div class="spinner-border spinner" role="status"></div>
      </div>
    </div>
  }
</div>

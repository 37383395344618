<div ngbDropdown class="user-dropdown">
  <button class="btn user-icon" ngbDropdownToggle>
    <i class="bi bi-person-fill"></i>
  </button>
  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
    <div ngbDropdownItem (click)="onProfileClick()">Profil</div>
    @if (isSeller) {
      <div ngbDropdownItem (click)="onSellerViewClick()">
        {{ isSellerView ? 'Käuferansicht' : 'Verkäuferansicht' }}
      </div>
    }
    <div ngbDropdownItem (click)="logout()">Logout</div>
  </div>
</div>
